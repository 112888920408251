import {
  GET_ME_REQUEST,
  GET_ME_SUCCESS,
  GET_ME_ERROR,
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_ERROR,
  RESET_USER,
  GET_ALLERGENS_REQUEST,
  GET_ALLERGENS_SUCCESS,
  GET_ALLERGENS_ERROR
} from "../actions/UserActions";

const initialState = {
  getUserLoading: false,
  updateProfileLoading: false,
  getAllergensLoading: false,
  user: null,
  allergens: []
};

function user(state = initialState, action) {
  switch (action.type) {
    case GET_ME_REQUEST:
      return Object.assign({}, state, {
        getUserLoading: true,
      });
    case GET_ME_SUCCESS:
      return Object.assign({}, state, {
        getUserLoading: false,
        user: action?.payload?.profile
      });
    case GET_ME_ERROR:
      return Object.assign({}, state, {
        getUserLoading: false,
        user: null
      });
    case UPDATE_PROFILE_REQUEST:
      return Object.assign({}, state, {
        updateProfileLoading: true,
      });
    case UPDATE_PROFILE_SUCCESS:
      return Object.assign({}, state, {
        updateProfileLoading: false,
      });
    case UPDATE_PROFILE_ERROR:
      return Object.assign({}, state, {
        updateProfileLoading: false,
      });
    case RESET_USER:
      return Object.assign({}, state, {
        getUserLoading: false,
        updateProfileLoading: false,
        getAllergensLoading: false,
        user: null,
        allergens: []
      });
    case GET_ALLERGENS_REQUEST:
      return Object.assign({}, state, {
        getAllergensLoading: true,
      });
    case GET_ALLERGENS_SUCCESS:
      return Object.assign({}, state, {
        getAllergensLoading: false,
        allergens: action?.payload?.allergens
      });
    case GET_ALLERGENS_ERROR:
      return Object.assign({}, state, {
        getAllergensLoading: false,
        allergens: []
      });

    default:
      return state;
  }
}

export default user;
