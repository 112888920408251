import {
  GET_ADDRESSES_REQUEST,
  GET_ADDRESSES_SUCCESS,
  GET_ADDRESSES_ERROR,
  ADD_ADDRESS_REQUEST,
  ADD_ADDRESS_SUCCESS,
  ADD_ADDRESS_ERROR,
  EDIT_ADDRESS_REQUEST,
  EDIT_ADDRESS_SUCCESS,
  EDIT_ADDRESS_ERROR,
  SET_CURRENT_ADDRESS,
  SET_USER_CURRENT_LOCATION,
  RESET_USER_CURRENT_LOCATION,
  SET_USER_SELECTED_LOCATION,
  SET_HANDLED_CURRENT_LOCATION,
  CLEAR_ADDRESSES_DATA,
  SET_NO_SELECTED_LOCATION_MESSAGE_POPPED_UP,
  SET_FAR_FROM_SELECTED_LOCATION_MESSAGE_CONFIRMED,
  SET_SHOW_SELECTED_LOCATION_ANIMATION,
  CLEAR_ALL_ADDRESSES_DATA
}
  from '../actions/AddressesActions';

const initialState = {
  loading: false,
  addAddressLoading: false,
  editAddressLoading: false,
  addresses: [],
  currentAddress: "",
  previousCurrentAddress: "",
  currentLocation: { lat: null, lng: null, location: null, addressDetails: null },
  userSelectedLocation: { lat: null, lng: null, location: null, addressDetails: null },
  previousUserSelectedLocation: { lat: null, lng: null, location: null, addressDetails: null },
  noSelectedLocationMessagePoppedUp: false,
  farFromSelectedLocationMessageConfirmed: false,
  showSelectedLocationAnimation: true,
  handledCurrentLocation: false,
};

const addresses = (state = initialState, action) => {
  switch (action.type) {
    case GET_ADDRESSES_REQUEST:
      return Object.assign({}, state, {
        loading: true,
      });
    case GET_ADDRESSES_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        addresses: action.payload.addresses,
      });
    case GET_ADDRESSES_ERROR:
      return Object.assign({}, state, {
        loading: false,
      });
    case ADD_ADDRESS_REQUEST:
      return Object.assign({}, state, {
        addAddressLoading: true,
      });
    case ADD_ADDRESS_SUCCESS:
      return Object.assign({}, state, {
        addAddressLoading: false,
        addresses: action?.payload?.addresses,
        //  currentAddress: action?.payload?.newAddressId
      });
    case ADD_ADDRESS_ERROR:
      return Object.assign({}, state, {
        addAddressLoading: false,
      });
    case EDIT_ADDRESS_REQUEST:
      return Object.assign({}, state, {
        editAddressLoading: true,
      });
    case EDIT_ADDRESS_SUCCESS:
      return Object.assign({}, state, {
        editAddressLoading: false,
        addresses: action?.payload?.addresses,
      });
    case EDIT_ADDRESS_ERROR:
      return Object.assign({}, state, {
        editAddressLoading: false,
      });
    case SET_CURRENT_ADDRESS:
      return Object.assign({}, state, {
        previousCurrentAddress: state.currentAddress ? state.currentAddress : action?.payload?.address,
        currentAddress: action.payload.address,
      });
    case SET_USER_CURRENT_LOCATION:
      return Object.assign({}, state, {
        currentLocation: { lat: action?.payload?.lat, lng: action?.payload?.lng, location: action?.payload?.location, addressDetails: action?.payload?.addressDetails }
      });

    case SET_USER_SELECTED_LOCATION:
      return Object.assign({}, state, {
        previousUserSelectedLocation: state?.userSelectedLocation?.lat && state?.userSelectedLocation?.lng ? state?.userSelectedLocation : { lat: action?.payload?.lat, lng: action?.payload?.lng, location: action?.payload?.location, addressDetails: action?.payload?.addressDetails },
        userSelectedLocation: { lat: action?.payload?.lat, lng: action?.payload?.lng, location: action?.payload?.location, addressDetails: action?.payload?.addressDetails }
      });

    case SET_HANDLED_CURRENT_LOCATION:
      return Object.assign({}, state, {
        handledCurrentLocation: action?.payload?.value
      });

    case RESET_USER_CURRENT_LOCATION:
      return Object.assign({}, state, {
        currentLocation: initialState?.currentLocation,
      });

    case CLEAR_ADDRESSES_DATA:
      return initialState;

    case SET_NO_SELECTED_LOCATION_MESSAGE_POPPED_UP:
      return Object.assign({}, state, {
        noSelectedLocationMessagePoppedUp: action?.payload?.value
      });

    case SET_FAR_FROM_SELECTED_LOCATION_MESSAGE_CONFIRMED:
      return Object.assign({}, state, {
        farFromSelectedLocationMessageConfirmed: action?.payload?.value
      });

    case SET_SHOW_SELECTED_LOCATION_ANIMATION:
      return Object.assign({}, state, {
        showSelectedLocationAnimation: action?.payload?.value
      });

    case CLEAR_ALL_ADDRESSES_DATA:
      return initialState;

    default:
      return state;
  }
}

export default addresses;
