// import { Geist, Geist_Mono } from "next/font/google";
// import "./globals.css";

// const geistSans = Geist({
//   variable: "--font-geist-sans",
//   subsets: ["latin"],
// });

// const geistMono = Geist_Mono({
//   variable: "--font-geist-mono",
//   subsets: ["latin"],
// });

// export const metadata = {
//   title: "Create Next App",
//   description: "Generated by create next app",
// };

// export default function RootLayout({ children }) {
//   return (
//     <html lang="en">
//       <body className={`${geistSans.variable} ${geistMono.variable}`}>
//         {children}
//       </body>
//     </html>
//   );
// }
'use client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { configurePersistor, configureStore } from './redux/store/store';
import ThemeWrapper from './theme';
import localforage from 'localforage';
import packageJson from '../../package.json';
import { useEffect } from 'react';
// import "../index.css";
// Redux setup
export const store = configureStore();
export const persistor = configurePersistor(store);

const checkForNewVersionAndClearCache = () => {
  const currentVersion = localStorage.getItem('appVersion');
  const newVersion = packageJson.version;

  // if (currentVersion !== newVersion) {
  //   localStorage.clear();
  //   localforage.dropInstance().then(() => { }).catch((err) => {
  //     console.error('Error deleting database:', err);
  //   });

  //   setTimeout(() => {
  //     window.location.reload();
  //   }, 0);

  //   localStorage.setItem('appVersion', newVersion);
  // }
};

export default function RootLayout({ children }) {
  useEffect(() => {
    checkForNewVersionAndClearCache();
  }, []);

  return (
    <>
      <html lang="en">
        <head></head>
        <body>
          <Provider store={store}>
            <PersistGate loading={<div />} persistor={persistor}>
              <ThemeWrapper>
                {children}
              </ThemeWrapper>
            </PersistGate>
          </Provider>
        </body>
      </html>
    </>
  );
}