import {
  SET_ORGANIZATION,
  GET_MODULES_REQUEST,
  GET_MODULES_SUCCESS,
  GET_MODULES_ERROR,
} from "../actions/OrganizationActions";

const initialState = {
  loading: false,
  activatedModules: [],
  allModules: [],
  organization: null
};

function organization(state = initialState, action) {
  switch (action.type) {
    case SET_ORGANIZATION:
      return Object.assign({}, state, {
        organization: action?.payload?.value
      })
    case GET_MODULES_REQUEST:
      return Object.assign({}, state, {
        loading: true,
      });
    case GET_MODULES_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        activatedModules: action?.payload?.activatedModules,
        allModules: action?.payload?.allModules,
      });
    case GET_MODULES_ERROR:
      return Object.assign({}, state, {
        loading: false,
      });
    default:
      return state;
  }
}

export default organization;
