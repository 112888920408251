import { appBarClasses } from "@mui/material";
import {
  GET_FEEDBACK_TEMPLATE_REQUEST,
  GET_FEEDBACK_TEMPLATE_SUCCESS,
  GET_FEEDBACK_TEMPLATE_ERROR,
  ADD_FEEDBACK_QR_REQUEST,
  ADD_FEEDBACK_QR_SUCCESS,
  ADD_FEEDBACK_QR_ERROR,
  ADD_FEEDBACK_REQUEST,
  ADD_FEEDBACK_SUCCESS,
  ADD_FEEDBACK_ERROR,
  RESET_FEEDBACK_TEMPLATE,
  CLEAR_ALL_FEEDBACK_DATA
} from "../actions/FeedbackActions";

const initialState = {
  addFeedbackLoading: false,
  //app
  getFeedbackTemplateLoading: false,
  feedbackTemplate: null,
  //dine in
  getDineInFeedbackTemplateLoading: false,
  dineInFeedbackTemplate: null,
  //pay
  getPayFeedbackTemplateLoading: false,
  payFeedbackTemplate: null,
  //QR
  getQRFeedbackTemplateLoading: false,
  QRFeedbackTemplate: null,
};

function feedback(state = initialState, action) {
  const source = action?.payload?.source;
  const feedbackTemplateState = source === "dine-in"
    ? "dineInFeedbackTemplate"
    : source === "pay"
      ? "payFeedbackTemplate"
      : source === "QR"
        ? "QRFeedbackTemplate"
        : "feedbackTemplate";
  const getFeedbackTemplateLoadingState = source === "dine-in"
    ? "getDineInFeedbackTemplateLoading"
    : source === "pay"
      ? "getPayFeedbackTemplateLoading"
      : source === "QR"
        ? "getQRFeedbackTemplateLoading"
        : "getFeedbackTemplateLoading";
  switch (action.type) {
    case GET_FEEDBACK_TEMPLATE_REQUEST:
      return Object.assign({}, state, {
        [getFeedbackTemplateLoadingState]: true,
      });
    case GET_FEEDBACK_TEMPLATE_SUCCESS:
      return Object.assign({}, state, {
        [getFeedbackTemplateLoadingState]: false,
        [feedbackTemplateState]: action.payload.feedbackTemplate[0],
      });
    case GET_FEEDBACK_TEMPLATE_ERROR:
      return Object.assign({}, state, {
        [getFeedbackTemplateLoadingState]: false,
      });
    case ADD_FEEDBACK_REQUEST:
      return Object.assign({}, state, {
        addFeedbackLoading: true,
      });
    case ADD_FEEDBACK_SUCCESS:
      return Object.assign({}, state, {
        addFeedbackLoading: false,
      });
    case ADD_FEEDBACK_ERROR:
      return Object.assign({}, state, {
        addFeedbackLoading: false,
      });
    case ADD_FEEDBACK_QR_REQUEST:
      return Object.assign({}, state, {
        addFeedbackLoading: true,
      });
    case ADD_FEEDBACK_QR_SUCCESS:
      return Object.assign({}, state, {
        addFeedbackLoading: false,
      });
    case ADD_FEEDBACK_QR_ERROR:
      return Object.assign({}, state, {
        addFeedbackLoading: false,
      });
    case RESET_FEEDBACK_TEMPLATE:
      return Object.assign({}, initialState);
    case CLEAR_ALL_FEEDBACK_DATA:
      return initialState
    default:
      return state;
  }
}

export default feedback;
