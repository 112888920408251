import {
  ADD_TO_CART,
  REMOVE_FROM_CART,
  UPDATE_CART,
  INCREMENT_CART_ITEM,
  DECREMENT_CART_ITEM,
  SET_CART_DETAILS,
  UPDATE_CART_DETAIL,
  UPDATE_CART_ORDER_INSTRUCTIONS,
  CLEAR_CART,
  PREPARE_CHECKOUT,
  UPDATE_CHECKOUT_DATA,
  SET_DINE_IN_ORDER_PAID,
  SET_PAY_ORDER_PAID,
  SET_COPIED_PROMOCODE,
  ADD_CART_DRAFT,
  REMOVE_CART_DRAFT,
  USE_CART_DRAFT_STARTED,
  USE_CART_DRAFT_ENDED,
  SET_CART_DRAFT_USED,
  SET_GOT_USED_MENUS,
  SET_ORIGINAL_POINTS,
  UPDATE_POINTS_USED,
  REMOVE_POINTS_USED,
  RESET_POINTS_DATA
} from "../actions/CartActions";

const initialState = {
  //APP
  cart: [],
  cartRestaurant: "",
  cartBranch: "",
  cartDeliveryMethod: "",
  cartUserAddress: "",
  cartCutleries: "yes",
  cartOrderInstructions: "",
  orderData: [],
  copiedPromocode: "",
  gotUsedMenus: false,
  pointsData: null,
  //APP CART DRAFTS
  cartDrafts: [],
  cartDraftUseLoading: false,
  //DINE IN
  dineInCart: [],
  orderDineInData: [],
  dineInCartOrderInstructions: "",
  dineInOrderPaid: false,
  dineInCopiedPromocode: "",
  dineInPointsData: null,
  //PAY
  payCart: [],
  orderPayData: [],
  payCartOrderInstructions: "",
  payOrderPaid: false,
  payCopiedPromocode: "",
  payPointsData: null,
  //QR
  QRCart: [],
  orderQRData: [],
  QRCartOrderInstructions: "",
  QRPointsData: null
};

const handleRemoveFromCart = (cart, additionTime) => {
  return [...cart].filter((item) => item.additionTime !== additionTime);
};

const handleAddToCart = (cart, cartItem) => {
  let newCart = [...cart] || [];
  newCart.push(cartItem);
  return newCart;
};

const handleIncrementCartItem = (cart, additionTime) => {
  let updatedCart = [];
  cart?.map((item) => {
    updatedCart.push(
      item?.additionTime === additionTime
        ? { ...item, qty: item?.qty + 1 }
        : item
    );
  });
  return updatedCart;
};

const handleDecrementCartItem = (cart, additionTime) => {
  let newQtyValue;
  let updatedCart = [];
  cart?.map((item) => {
    if (item?.additionTime === additionTime) {
      newQtyValue = item?.qty - 1;
    }
    updatedCart.push(
      item?.additionTime === additionTime
        ? { ...item, qty: item?.qty - 1 }
        : item
    );
  });
  return {
    updatedCart: updatedCart,
    newQtyValue: newQtyValue
  }
};

const handleUpdateCart = (cart, id, additionTime, cartData) => {
  let updatedCart = [];
  cart?.map((item) => {
    updatedCart.push(
      item?.additionTime == id
        ? {
          additionTime: additionTime,
          ...cartData,
        }
        : item
    );
  });
  return updatedCart;
};

const getUpdatedCartDrafts = (cartDrafts, ruuid, action, cartData) => {
  let updatedCartDrafts = [];

  if (action === "add") {
    // Check if ruuid doesn't exist, then add it
    const ruuidExists = cartDrafts.some((cartDraft) => cartDraft?.ruuid === ruuid);
    if (!ruuidExists) {
      updatedCartDrafts = [...cartDrafts, {
        ruuid: ruuid,
        cart: {
          ...cartData
        },
        isUsed: false,
      }];
    } else {
      // Update the cartData for the existing ruuid
      updatedCartDrafts = cartDrafts.map((cartDraft) => {
        if (cartDraft?.ruuid === ruuid) {
          return {
            ruuid: ruuid,
            cart: {
              ...cartData
            },
            isUsed: false
          };
        } else {
          return {
            ...cartDraft
          };
        }
      });
    }
  } else if (action === "remove") {
    updatedCartDrafts = cartDrafts?.filter((cartDraft) => cartDraft?.ruuid !== ruuid);
  }

  return updatedCartDrafts;
};


function cart(state = initialState, action) {
  const appSource = action?.payload?.appSource;
  const cartArray =
    appSource === "QR"
      ? "QRCart"
      : appSource === "pay"
        ? "payCart"
        : appSource === "dine-in"
          ? "dineInCart"
          : "cart";
  const cartInstructions =
    appSource === "QR"
      ? "QRCartOrderInstructions"
      : appSource === "pay"
        ? "payCartOrderInstructions"
        : appSource === "dine-in"
          ? "dineInCartOrderInstructions"
          : "cartOrderInstructions";
  const copiedPromocodeState =
    appSource === "pay"
      ? "payCopiedPromocode"
      : appSource === "dine-in"
        ? "dineInCopiedPromocode"
        : "copiedPromocode";
  const data =
    appSource === "QR"
      ? "orderQRData"
      : appSource === "pay"
        ? "orderPayData"
        : appSource === "dine-in"
          ? "orderDineInData"
          : "orderData";

  switch (action.type) {
    case ADD_TO_CART:
      return Object.assign({}, state, {
        gotUsedMenus: action?.payload?.appSource === "APP" && state?.cart?.length === 0 ? true : state?.gotUsedMenus,
        [cartArray]: handleAddToCart(state[cartArray], action.payload.cartItem),
      });

    case REMOVE_FROM_CART:
      return Object.assign({}, state, {
        cartCutleries:
          (state?.cart?.length > 1 && cartArray === "cart") ||
            cartArray !== "cart"
            ? state.cartCutleries
            : "yes",
        [cartInstructions]:
          state[cartArray] === 1 ? "" : state[cartInstructions],
        [cartArray]: handleRemoveFromCart(
          state[cartArray],
          action.payload.additionTime
        ),
      });

    case UPDATE_CART:
      return Object.assign({}, state, {
        [cartArray]: handleUpdateCart(
          state[cartArray],
          action.payload.id,
          action.payload.additionTime,
          action.payload.cartData
        ),
      });

    case INCREMENT_CART_ITEM:
      return Object.assign({}, state, {
        [cartArray]: handleIncrementCartItem(
          state[cartArray],
          action?.payload?.additionTime
        ),
      });
    case DECREMENT_CART_ITEM:
      const decrementData = handleDecrementCartItem(
        state[cartArray],
        action?.payload?.additionTime
      );

      const decrementCartItemPointsDataKey = appSource === "QR"
        ? "QRPointsData"
        : appSource === "dine-in"
          ? "dineInPointsData"
          : appSource === "pay"
            ? "payPointsData"
            : "pointsData";

      const qtySelectedToBeRedeemed = state[decrementCartItemPointsDataKey] ? state[decrementCartItemPointsDataKey]?.pointsUsed?.find((row) => {
        return row?.additionTime === action?.payload?.additionTime
      })?.qtyToBeRedeemed || 0 : -1;

      let newPointsDataValue;

      if (qtySelectedToBeRedeemed !== -1 && decrementData?.newQtyValue < qtySelectedToBeRedeemed) {
        let updatedPointsUsed = [];

        state[decrementCartItemPointsDataKey]?.pointsUsed?.forEach((row) => {
          if (row?.additionTime === action?.payload?.additionTime) {
            updatedPointsUsed.push({
              ...row,
              qtyToBeRedeemed: decrementData?.newQtyValue
            })
          } else {
            updatedPointsUsed.push({
              ...row
            })
          }
        })
        newPointsDataValue = {
          ...state[decrementCartItemPointsDataKey],
          pointsUsed: updatedPointsUsed
        }
      } else {
        newPointsDataValue = {
          ...state[decrementCartItemPointsDataKey]
        }
      }
      return Object.assign({}, state, {
        [cartArray]: decrementData?.updatedCart,
        [decrementCartItemPointsDataKey]: newPointsDataValue
      });

    case SET_CART_DETAILS:
      return Object.assign({}, state, {
        cartRestaurant: action?.payload?.cartRestaurant,
        cartBranch: action?.payload?.branch,
        cartDeliveryMethod: action?.payload?.deliveryMethod,
      });
    case UPDATE_CART_DETAIL:
      return Object.assign({}, state, {
        [action?.payload?.detail]: action?.payload?.value,
      });
    case UPDATE_CART_ORDER_INSTRUCTIONS:
      return Object.assign({}, state, {
        [cartInstructions]: action?.payload?.value,
      });
    case CLEAR_CART:
      return Object.assign({}, state, {
        [cartArray]: [],
        cartBranch:
          appSource === "dine-in" || appSource === "QR"
            ? state?.cartBranch
            : "",
        cartDeliveryMethod:
          appSource === "dine-in" || appSource === "QR"
            ? state?.cartDeliveryMethod
            : "",
        cartCutleries:
          appSource === "dine-in" || appSource === "QR"
            ? state?.cartCutleries
            : "yes",
        [cartInstructions]: "",
      });
    case PREPARE_CHECKOUT:
      return Object.assign({}, state, {
        [data]: action.payload.orderData || [],
      });
    case UPDATE_CHECKOUT_DATA:
      return Object.assign({}, state, {
        [data]: {
          ...state[data],
          [action?.payload?.field]: action?.payload?.value,
        },
      });
    case SET_DINE_IN_ORDER_PAID:
      return Object.assign({}, state, {
        dineInOrderPaid: action?.payload?.value,
        dineInCart: [],
      });
    case SET_PAY_ORDER_PAID:
      return Object.assign({}, state, {
        payOrderPaid: action?.payload?.value,
        orderPayData:
          action?.payload?.value === true
            ? { items: [...state?.payCart] }
            : { ...state?.orderPayData },
        payCart: action?.payload?.value === true ? [] : [...state?.payCart],
      });
    case SET_COPIED_PROMOCODE:
      return Object.assign({}, state, {
        [copiedPromocodeState]: action?.payload?.value,
      });
    case ADD_CART_DRAFT:
      return Object.assign({}, state, {
        cartDrafts: getUpdatedCartDrafts(state.cartDrafts, action?.payload?.ruuid, "add", {
          cart: [...state?.cart],
          cartDeliveryMethod: action?.payload?.deliveryMethod,
          cartBranch: state?.cartBranch,
          cartUserAddress: state?.cartUserAddress,
          cartCutleries: state?.cartCutleries,
          cartOrderInstructions: state?.cartOrderInstructions,
          orderData: state?.orderData,
          currentAddress: action?.payload?.currentAddress
        })
      });
    case REMOVE_CART_DRAFT:
      return Object.assign({}, state, {
        cartDrafts: getUpdatedCartDrafts(state.cartDrafts, action?.payload?.ruuid, "remove", null)
      });
    case USE_CART_DRAFT_STARTED:
      return Object.assign({}, state, {
        cartDraftUseLoading: true
      });
    case USE_CART_DRAFT_ENDED:
      return Object.assign({}, state, {
        cartDraftUseLoading: false,
        cartDrafts: state.cartDrafts.map((cartDraft) => {
          if (cartDraft?.ruuid === action?.payload?.ruuid) {
            return {
              ...cartDraft,
              isUsed: true
            }
          } else {
            return {
              ...cartDraft
            }
          }
        })
      });
    case SET_CART_DRAFT_USED:
      return Object.assign({}, state, {
        cartDrafts: state.cartDrafts.map((cartDraft) => {
          if (cartDraft?.ruuid === action?.payload?.ruuid) {
            return {
              ...cartDraft,
              isUsed: true
            }
          } else {
            return {
              ...cartDraft
            }
          }
        })
      });
    case SET_GOT_USED_MENUS:
      return Object.assign({}, state, {
        gotUsedMenus: action?.payload?.value
      })
    case SET_ORIGINAL_POINTS:
      const key = appSource === "QR"
        ? "QRPointsData"
        : appSource === "dine-in"
          ? "dineInPointsData"
          : appSource === "pay"
            ? "payPointsData"
            : "pointsData";

      return Object.assign({}, state, {
        [key]: {
          originalPoints: action?.payload?.value,
          ...state[key],
        }
      });
    case UPDATE_POINTS_USED:
      const updatePointsUsedKey = appSource === "QR"
        ? "QRPointsData"
        : appSource === "dine-in"
          ? "dineInPointsData"
          : appSource === "pay"
            ? "payPointsData"
            : "pointsData";

      const updatePointsFilteredArray = state[updatePointsUsedKey] && state[updatePointsUsedKey]?.pointsUsed?.length > 0 ? state[updatePointsUsedKey]?.pointsUsed?.filter((row) => {
        return row?.additionTime !== action?.payload?.additionTime;
      })
        : [];

      return Object.assign({}, state, {
        [updatePointsUsedKey]: {
          originalPoints: state[updatePointsUsedKey]?.originalPoints,
          pointsUsed: [
            ...updatePointsFilteredArray,
            action?.payload?.value
          ]
        }
      })
    case REMOVE_POINTS_USED:
      const removePointsUsedKey = appSource === "QR"
        ? "QRPointsData"
        : appSource === "dine-in"
          ? "dineInPointsData"
          : appSource === "pay"
            ? "payPointsData"
            : "pointsData";

      const removePointsFilteredArray
        = state[removePointsUsedKey]?.pointsUsed?.filter((row) => {
          return row?.additionTime !== action?.payload?.additionTime;
        })

      if (removePointsFilteredArray?.length === 0) {
        return Object.assign({}, state, {
          [removePointsUsedKey]: null
        })
      } else {
        return Object.assign({}, state, {
          [removePointsUsedKey]: {
            originalPoints: state[removePointsUsedKey]?.originalPoints,
            pointsUsed: [
              ...removePointsFilteredArray,
            ]
          }
        })
      }
    case RESET_POINTS_DATA:
      const resetPointsDataKey = appSource === "QR"
        ? "QRPointsData"
        : appSource === "dine-in"
          ? "dineInPointsData"
          : appSource === "pay"
            ? "payPointsData"
            : "pointsData";

      return Object.assign({}, state, {
        [resetPointsDataKey]: null
      })
    default:
      return state;
  }
}

export default cart;