import {
  SET_APP_SOURCE,
  RESET_APP_SOURCE
} from "../actions/AppSourceActions";

const initialState = {
  appSource: "APP"
};

function appSource(state = initialState, action) {
  switch (action.type) {
    case SET_APP_SOURCE:
      return {
        ...state,
        appSource: action?.payload?.value,
      };
    case RESET_APP_SOURCE:
      return initialState
    default:
      return state;
  }
}

export default appSource;
