import { getAddresses, addAddress, editAddress } from '../../config/config';
import { LOGOUT } from './AuthActions';
import { notify } from '../../utils/notify';

export const GET_ADDRESSES_REQUEST = 'GET_ADDRESSES_REQUEST';
export const GET_ADDRESSES_SUCCESS = 'GET_ADDRESSES_SUCCESS';
export const GET_ADDRESSES_ERROR = 'GET_ADDRESSES_ERROR';
export const ADD_ADDRESS_REQUEST = 'ADD_ADDRESS_REQUEST';
export const ADD_ADDRESS_SUCCESS = 'ADD_ADDRESS_SUCCESS';
export const ADD_ADDRESS_ERROR = 'ADD_ADDRESS_ERROR';
export const EDIT_ADDRESS_REQUEST = 'EDIT_ADDRESS_REQUEST';
export const EDIT_ADDRESS_SUCCESS = 'EDIT_ADDRESS_SUCCESS';
export const EDIT_ADDRESS_ERROR = 'EDIT_ADDRESS_ERROR';
export const SET_CURRENT_ADDRESS = 'SET_CURRENT_ADDRESS';
export const SET_USER_CURRENT_LOCATION = 'SET_USER_CURRENT_LOCATION';
export const RESET_USER_CURRENT_LOCATION = 'RESET_USER_CURRENT_LOCATION';
export const SET_USER_SELECTED_LOCATION = 'SET_USER_SELECTED_LOCATION';
export const CLEAR_ADDRESSES_DATA = 'CLEAR_ADDRESSES_DATA';
export const SET_NO_SELECTED_LOCATION_MESSAGE_POPPED_UP = 'SET_NO_SELECTED_LOCATION_MESSAGE_POPPED_UP';
export const SET_FAR_FROM_SELECTED_LOCATION_MESSAGE_CONFIRMED = 'SET_FAR_FROM_SELECTED_LOCATION_MESSAGE_CONFIRMED';
export const SET_SHOW_SELECTED_LOCATION_ANIMATION = 'SET_SHOW_SELECTED_LOCATION_ANIMATION';
export const SET_HANDLED_CURRENT_LOCATION = 'SET_HANDLED_CURRENT_LOCATION';
export const CLEAR_ALL_ADDRESSES_DATA = 'CLEAR_ALL_ADDRESSES_DATA';

export const actions = {

  getAddresses: () => async (dispatch) => {
    dispatch({ type: GET_ADDRESSES_REQUEST });
    await getAddresses().then((res) => {
      if (res?.status === 200) {
        dispatch({
          type: GET_ADDRESSES_SUCCESS,
          payload: {
            addresses: res?.data?.adresses
          },
        });
      } else {
        if (res.status === 401) {
          dispatch({ type: LOGOUT })
        }
        dispatch({
          type: GET_ADDRESSES_ERROR,
        });
        notify.error(res?.data?.message || 'Get Addresses error');
      }
    });
  },

  addAddress: (data, onSuccess) => async (dispatch) => {
    dispatch({ type: ADD_ADDRESS_REQUEST });
    await addAddress(data).then((res) => {
      if (res?.status === 200) {
        dispatch({
          type: ADD_ADDRESS_SUCCESS,
          payload: { addresses: res?.data?.adresses }
        });
        onSuccess(res?.data?.addedAddress);
      } else {
        if (res.status === 401) {
          dispatch({ type: LOGOUT })
        }
        dispatch({
          type: ADD_ADDRESS_ERROR,
        });
        notify.error(res?.data?.message || 'Add Address error');
      }
    });
  },

  editAddress: (addressId, data, onSuccess) => async (dispatch) => {
    dispatch({ type: EDIT_ADDRESS_REQUEST });
    await editAddress(addressId, data).then((res) => {
      if (res?.status === 200) {
        dispatch({
          type: EDIT_ADDRESS_SUCCESS,
          payload: { addresses: res?.data?.adresses }
        });
        onSuccess();
      } else {
        if (res.status === 401) {
          dispatch({ type: LOGOUT })
        }
        dispatch({
          type: EDIT_ADDRESS_ERROR,
        });
        notify.error(res?.data?.message || 'Edit Address error');
      }
    });
  },

  setCurrentAddress: (address) => (dispatch) => {
    dispatch({ type: SET_CURRENT_ADDRESS, payload: { address } });
  },

  //SET USER CURRENT LOCATION
  setUserCurrentLocation: (lat, lng, location, addressDetails) =>
    (dispatch) => {
      dispatch({
        type: SET_USER_CURRENT_LOCATION,
        payload: {
          lat, lng, location, addressDetails
        },
      });
    },

  //RESET USER CURRENT LOCATION
  resetUserCurrentLocation: () =>
    (dispatch) => {
      dispatch({
        type: RESET_USER_CURRENT_LOCATION,
      });
    },

  //SET USER SELECTED LOCATION
  setUserSelectedLocation: (lat, lng, location, addressDetails) =>
    (dispatch) => {
      dispatch({
        type: SET_USER_SELECTED_LOCATION,
        payload: {
          lat, lng, location, addressDetails
        },
      });
    },

  //SET HANDLED CURRENT LOCATION
  setHandledCurrentLocation: (value) =>
    (dispatch) => {
      dispatch({
        type: SET_HANDLED_CURRENT_LOCATION,
        payload: {
          value: value
        }
      })
    },

  //CLEAR ADDRESSES DATA
  clearAddressesData: () =>
    (dispatch) => {
      dispatch({
        type: CLEAR_ADDRESSES_DATA,
      })
    },

  //SET NO SELECTED LOCATION MESSAGE POPPED UP
  setNoSelectedLocationMessagePoppedUp: (value) =>
    (dispatch) => {
      dispatch({
        type: SET_NO_SELECTED_LOCATION_MESSAGE_POPPED_UP,
        payload: {
          value: value
        }
      })
    },

  //SET FAR FROM SELECTED LOCATION MESSAGE CONFIRMED
  setFarFromSelectedLocationMessageConfirmed: (value) =>
    (dispatch) => {
      dispatch({
        type: SET_FAR_FROM_SELECTED_LOCATION_MESSAGE_CONFIRMED,
        payload: {
          value: value
        }
      })
    },

  //SET SHOW SELECTED LOCATION ANIMATION
  setShowSelectedLocationAnimation: (value) =>
    (dispatch) => {
      dispatch({
        type: SET_SHOW_SELECTED_LOCATION_ANIMATION,
        payload: {
          value: value
        }
      })
    },

  //CLEAR ALL
  clearAllAddressesData: () =>
    (dispatch) => {
      dispatch({
        type: CLEAR_ADDRESSES_DATA
      })
    }
};

