import {
  SIGNUP_REQUEST,
  SIGNUP_SUCCESS,
  SIGNUP_ERROR,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  VERIFY_REQUEST,
  VERIFY_SUCCESS,
  VERIFY_ERROR,
  LOGOUT,
} from "../actions/AuthActions";

const initialState = {
  signupLoading: false,
  loginLoading: false,
  verifyLoading: false,
  token: null,
};

function auth(state = initialState, action) {
  switch (action.type) {
    case SIGNUP_REQUEST:
      return Object.assign({}, state, {
        signupLoading: true,
      });
    case SIGNUP_SUCCESS:
      return Object.assign({}, state, {
        signupLoading: false,
      });
    case SIGNUP_ERROR:
      return Object.assign({}, state, {
        signupLoading: false,
      });
    case LOGIN_REQUEST:
      return Object.assign({}, state, {
        loginLoading: true,
      });
    case LOGIN_SUCCESS:
      return Object.assign({}, state, {
        loginLoading: false,
      });
    case LOGIN_ERROR:
      return Object.assign({}, state, {
        loginLoading: false,
      });
    case VERIFY_REQUEST:
      return Object.assign({}, state, {
        verifyLoading: true,
      });
    case VERIFY_SUCCESS:
      return Object.assign({}, state, {
        verifyLoading: false,
        token: action?.payload?.token,
      });
    case VERIFY_ERROR:
      return Object.assign({}, state, {
        verifyLoading: false,
      });
    case LOGOUT:
      return Object.assign({}, state, {
        token: null,
        signupLoading: false,
        loginLoading: false,
        verifyLoading: false,
      });

    default:
      return state;
  }
}
export default auth;
