import {
    GET_MESSAGES_REQUEST,
    GET_MESSAGES_SUCCESS,
    GET_MESSAGES_ERROR,
    FETCH_MORE_MESSAGES_REQUEST,
    FETCH_MORE_MESSAGES_SUCCESS,
    FETCH_MORE_MESSAGES_ERROR,
    SEND_MESSAGE_REQUEST,
    SEND_MESSAGE_SUCCESS,
    SEND_MESSAGE_ERROR,
    GET_UNREAD_MESSAGES_REQUEST,
    GET_UNREAD_MESSAGES_SUCCESS,
    GET_UNREAD_MESSAGES_ERROR,
    SET_MESSAGES_USER_SELECTED_BRANCH,
    CLEAR_MESSAGES_USER_SELECTED_BRANCH,
    CLEAR_ALL_MESSAGES_DATA
} from "../actions/MessagesActions";

const initialState = {
    getMessagesLoading: false,
    fetchMoreMessagesLoading: false,
    getUnreadMessagesLoading: false,
    sendMessageLoading: false,
    messagesRestaurant: "",
    messagesBranch: "",
    chatDetails: null,
    messages: [],
    totalMessages: 0,
    totalPages: 0,
    currentPage: 0,
    unreadMessagesNumber: 0,
    unreadMessagesChatsDetails: null,
    messagesUserSelectedBranch: ""
};

function messages(state = initialState, action) {
    const getUpdatedMessagesArray = (initialMessagesArray, message, user) => {
        const newMessageObject = {
            createdAt: new Date().toISOString(),
            message: message,
            sender: {
                firstName: user?.firstName,
                lastName: user?.lastName,
                type: "is-app-user",
            }
        }

        let updatedArray;

        if (initialMessagesArray && initialMessagesArray?.length > 0) {
            updatedArray = [
                ...initialMessagesArray,
                newMessageObject
            ]
        } else {
            updatedArray = [
                newMessageObject
            ]
        }

        return updatedArray;
    }

    const getUpdatedUnreadMessagesStates = (ruuid, buuid, initialStateValue, initialUnreadMessagesStateValue, messagesRestaurant) => {
        if (messagesRestaurant !== ruuid || initialUnreadMessagesStateValue === 0) {
            return {
                updatedUnreadMessagesChatsDetails: initialStateValue,
                updatedUnreadMessagesNumber: initialUnreadMessagesStateValue
            }
        }
        //check if the branch is included in unread messages chats and has unread count greater than 0
        let readMessagesCount = 0;
        let updatedArray = [];

        initialStateValue?.forEach((chatDetail) => {
            const branchChatFound = (chatDetail?.chat?.initiator?.type === "is-branch" && chatDetail?.chat?.initiator?.uuid === buuid || chatDetail?.chat?.recipient?.type === "is-branch" && chatDetail?.chat?.recipient?.uuid === buuid) ? chatDetail : null;

            if (branchChatFound) {
                readMessagesCount = branchChatFound?.chat?.unreadCount;

                updatedArray.push({
                    chat: {
                        ...chatDetail?.chat,
                        unreadCount: 0
                    }
                });
            } else {
                updatedArray.push({
                    chat: {
                        ...chatDetail?.chat
                    }
                })
            }
        })

        let updatedUnreadMessages;

        if (initialUnreadMessagesStateValue && initialUnreadMessagesStateValue > 0) {
            if (readMessagesCount > 0) {
                updatedUnreadMessages = readMessagesCount <= initialUnreadMessagesStateValue ? initialUnreadMessagesStateValue - readMessagesCount : 0;
            } else {
                updatedUnreadMessages = initialUnreadMessagesStateValue;
            }
        }
        return {
            updatedUnreadMessagesChatsDetails: updatedArray,
            updatedUnreadMessagesNumber: updatedUnreadMessages
        }
    }

    switch (action.type) {
        case GET_MESSAGES_REQUEST:

            return Object.assign({}, state, {
                // getMessagesLoading: true,
                getMessagesLoading:
                    action?.payload?.ruuid === state?.messagesRestaurant && action?.payload?.buuid === state?.messagesBranch &&
                        !state?.unreadMessagesChatsDetails?.find((chat) => {
                            const branchData = chat?.chat?.recipient?.type === "is-branch" ? chat?.chat?.recipient : chat?.chat?.initiator;

                            return branchData?.uuid === action?.payload?.buuid && chat?.chat?.unreadCount && chat?.chat?.unreadCount > 0
                        }) ? false : true,
            });
        case GET_MESSAGES_SUCCESS:
            const updatedUnreadMessagesStatesValue = getUpdatedUnreadMessagesStates(
                action?.payload?.ruuid,
                action?.payload?.buuid,
                state.unreadMessagesChatsDetails,
                state?.unreadMessagesNumber,
                state?.messagesRestaurant
            );

            return Object.assign({}, state, {
                messagesRestaurant: action?.payload?.ruuid,
                messagesBranch: action?.payload?.buuid,
                getMessagesLoading: false,
                chatDetails: action?.payload?.chatDetails,
                messages: action?.payload?.messages,
                currentPage: action?.payload?.pageNumber,
                totalPages: action?.payload?.totalPages,
                totalMessages: action?.payload?.totalMessages,
                unreadMessagesChatsDetails: updatedUnreadMessagesStatesValue?.updatedUnreadMessagesChatsDetails,
                unreadMessagesNumber: updatedUnreadMessagesStatesValue?.updatedUnreadMessagesNumber
            });

        case GET_MESSAGES_ERROR:
            return Object.assign({}, state, {
                messagesRestaurant: action?.payload?.ruuid,
                messagesBranch: action?.payload?.buuid,
                getMessagesLoading: false,
                chatDetails: null,
                messages: [],
                totalMessages: 0,
                totalPages: 0,
                currentPage: 0,
            });
        case FETCH_MORE_MESSAGES_REQUEST:
            return Object.assign({}, state, {
                fetchMoreMessagesLoading: true,
            })
        case FETCH_MORE_MESSAGES_SUCCESS:
            return Object.assign({}, state, {
                fetchMoreMessagesLoading: false,
                messages: [...state.messages, ...action?.payload?.newMessages],
                currentPage: action?.payload?.pageNumber
            });
        case FETCH_MORE_MESSAGES_ERROR:
            return Object.assign({}, state, {
                fetchMoreMessagesLoading: false,
            });
        case SEND_MESSAGE_REQUEST:
            return Object.assign({}, state, {
                sendMessageLoading: true,
                messages: getUpdatedMessagesArray(state?.messages, action?.payload?.messages?.messages[0]?.message, action?.payload?.user)
                //continue
            });
        case SEND_MESSAGE_SUCCESS:
            return Object.assign({}, state, {
                sendMessageLoading: false,
            });
        case SEND_MESSAGE_ERROR:
            return Object.assign({}, state, {
                sendMessageLoading: false,
            });
        case GET_UNREAD_MESSAGES_REQUEST:
            return Object.assign({}, state, {
                getUnreadMessagesLoading: action?.payload?.ruuid === state?.messagesRestaurant ? false : true,
            });
        case GET_UNREAD_MESSAGES_SUCCESS:
            return Object.assign({}, state, {
                messagesRestaurant: action?.payload?.ruuid,
                getUnreadMessagesLoading: false,
                unreadMessagesNumber: action?.payload?.unreadMessagesNumber,
                unreadMessagesChatsDetails: action?.payload?.unreadMessagesChatsDetails
            });
        case GET_UNREAD_MESSAGES_ERROR:
            return Object.assign({}, state, {
                messagesRestaurant: action?.payload?.ruuid,
                getUnreadMessagesLoading: false,
                //check if we should set unread messages to 0 and chat details to null
            });
        case SET_MESSAGES_USER_SELECTED_BRANCH:
            return Object.assign({}, state, {
                messagesUserSelectedBranch: action?.payload?.buuid
            });
        case CLEAR_MESSAGES_USER_SELECTED_BRANCH:
            return Object.assign({}, state, {
                messagesUserSelectedBranch: ""
            });
        case CLEAR_ALL_MESSAGES_DATA:
            return Object.assign({}, initialState);
        default:
            return state;
    }
}

export default messages;
