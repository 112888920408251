import { CHANGE_DELIVERY_METHOD } from '../actions/DeliveryMethodActions';

const initialState = {
  deliveryMethod: "",
};

function deliveryMethod(state = initialState, action) {
  switch (action.type) {
    case CHANGE_DELIVERY_METHOD:
      return Object.assign({}, state, {
        deliveryMethod: action.payload,
      });
    default:
      return state;
  }
}

export default deliveryMethod;
