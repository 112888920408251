import {
  GET_PROMOTIONS_REQUEST,
  GET_PROMOTIONS_SUCCESS,
  GET_PROMOTIONS_ERROR,
  GET_PROMOTION_REQUEST,
  GET_PROMOTION_SUCCESS,
  GET_PROMOTION_ERROR,
  CLEAR_ALL_PROMOTIONS_DATA,
} from "../actions/PromotionsActions";

const initialState = {
  promotions: null,
  getPromotionsLoading: false,
  promotion: null,
  getPromotionLoading: false,
};

function promotions(state = initialState, action) {
  switch (action.type) {
    case GET_PROMOTIONS_REQUEST:
      return Object.assign({}, state, {
        getPromotionsLoading: state?.promotions ? false : true,
      });
    case GET_PROMOTIONS_SUCCESS:
      return Object.assign({}, state, {
        getPromotionsLoading: false,
        promotions: action.payload.promotions,
      });
    case GET_PROMOTIONS_ERROR:
      return Object.assign({}, state, {
        getPromotionsLoading: false,
      });
    case GET_PROMOTION_REQUEST:
      return Object.assign({}, state, {
        getPromotionLoading: true,
      });
    case GET_PROMOTION_SUCCESS:
      return Object.assign({}, state, {
        getPromotionLoading: false,
        promotion: action.payload.promotion,
      });
    case GET_PROMOTION_ERROR:
      return Object.assign({}, state, {
        getPromotionLoading: false,
        promotion: null,
      });
    case CLEAR_ALL_PROMOTIONS_DATA:
      return initialState;
    default:
      return state;
  }
}

export default promotions;
