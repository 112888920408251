import {
    SET_REFERENCE
}
    from "../actions/AffiliateLinksActions";

const initialState = {
    reference: null
};

const affiliateLinks = (state = initialState, action) => {
    switch (action.type) {
        case SET_REFERENCE:
            return Object.assign({}, state, {
                reference: action?.payload?.reference,
            });
        default:
            return state;
    }
}

export default affiliateLinks;