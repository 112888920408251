import { toast } from 'react-toastify';

const showMessages = process.env.REACT_APP_SHOW_MESSAGES === 'true';

export const notify = {
  error: (message, options = {}) => {
    if (showMessages) {
      toast.error(message, options);
    }
  },
  message: (message, options = {}) => {
    if (showMessages) {
      toast(message, options);
    }
  },
};
