import {
  GET_MENUS_REQUEST,
  GET_MENUS_SUCCESS,
  GET_MENUS_ERROR,
  RESET_MENUS,
  SET_SELECTED_MENU,
  RESET_SELECTED_MENU,
  GET_PRODUCTS_REQUEST,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_ERROR,
  CHANGE_PRODUCTS_VIEW,
  SET_INGREDIENTS_FILTER,
  SET_TYPES_AND_ALLERGIES_FILTER,
  SET_TRENDING_FILTER,
  SET_OFFERS_FILTER,
  SET_FAVORITES_FILTER,
  SET_PRICE_FILTER,
  CLEAR_FILTERS,
  CLEAR_ALL_PRODUCTS_DATA
} from "../actions/ProductsActions";

const initialState = {
  getMenusLoading: false,
  loading: false,

  //APP
  menusSelectedRestaurant: null,
  menus: [],
  menusData: [],
  selectedMenu: null,
  branchAllMenusProducts: null,
  comboProducts: null,
  products: [],
  groups: [],
  ingredients: [],
  typesAndAllergies: [],
  ingredientsFilter: [],
  typesAndAllergiesFilter: [],
  trendingFilter: false,
  offersFilter: false,
  favoritesFilter: false,
  priceFilter: [],
  view: "grid",

  //DINE IN
  dineInMenusSelectedRestaurant: null,
  dineInMenus: [],
  dineInMenusData: [],
  dineInSelectedMenu: null,
  dineInBranchAllMenusProducts: null,
  dineInComboProducts: null,
  dineInProducts: [],
  dineInGroups: [],
  dineInIngredients: [],
  dineInTypesAndAllergies: [],
  dineInIngredientsFilter: [],
  dineInTypesAndAllergiesFilter: [],
  dineInTrendingFilter: false,
  dineInOffersFilter: false,
  dineInFavoritesFilter: false,
  dineInPriceFilter: [],
  dineInView: "grid",

  //PAY
  payMenusSelectedRestaurant: null,
  payMenus: [],
  payMenusData: [],
  paySelectedMenu: null,
  payBranchAllMenusProducts: null,
  payComboProducts: null,
  payProducts: [],
  payGroups: [],
  payIngredients: [],
  payTypesAndAllergies: [],
  payIngredientsFilter: [],
  payTypesAndAllergiesFilter: [],
  payTrendingFilter: false,
  payOffersFilter: false,
  payFavoritesFilter: false,
  payPriceFilter: [],
  payView: "grid",

  //QR
  QRMenusSelectedRestaurant: "",
  QRmenus: [],
  QRMenusData: [],
  QRSelectedMenu: null,
  QRBranchAllMenusProducts: null,
  QRComboProducts: null,
  QRProducts: [],
  QRGroups: [],
  QRIngredientsFilter: [],
  QRTypesAndAllergiesFilter: [],
  QRTrendingFilter: false,
  QROffersFilter: false,
  QRFavoritesFilter: false,
  QRPriceFilter: [],
  QRView: "grid",
};

// const getMenusData = (menuData, menus, menusData) => {
//   console.log('menu data', menuData)
//   console.log('menus', menus)
//   console.log('menus data', menusData)
//   let menusDataArray = [...menusData];
//   if (menusData?.length === 0) {
//     menusDataArray.push({
//       ...menuData,
//       branches: menus?.find((m) => {
//         return m?.uuid === menuData?.uuid;
//       })?.branches,
//     });
//   } else {
//     if (
//       !menusData?.find((m) => {
//         return m?.uuid === menuData?.uuid;
//       })
//     ) {
//       menusDataArray?.push({
//         ...menuData,
//         branches: menus?.find((m) => {
//           return m?.uuid === menuData?.uuid;
//         })?.branches,
//       });
//     }
//   } else {

//   }
//   return menusDataArray;
// };
const getMenusData = (menuData, menus, menusData) => {
  let menusDataArray = [...menusData];

  if (menusDataArray.length === 0) {
    menusDataArray.push({
      ...menuData,
      branches: menus?.find((m) => m?.uuid === menuData?.uuid)?.branches,
    });
  } else {
    const existingMenuIndex = menusDataArray.findIndex((m) => m?.uuid === menuData?.uuid);

    if (existingMenuIndex === -1) {
      menusDataArray.push({
        ...menuData,
        branches: menus?.find((m) => m?.uuid === menuData?.uuid)?.branches,
      });
    } else {
      menusDataArray[existingMenuIndex] = {
        ...menuData,
        branches: menus?.find((m) => m?.uuid === menuData?.uuid)?.branches,
      };
    }
  }

  return menusDataArray;
};


// const getUniqueProductsForBranch = (branchUUID, menusdata, productsReceived) => {
//   const uniqueProductsArray = [];
//   console.log('shou9ld get all unique')

//   for (const menu of menusdata) {
//     // Check if the menu is excluded for the branch UUID
//     // if (menu?.branches?.includes(branchUUID)) {
//     //   continue;
//     // }

//     if(!menu?.branches?.includes(branchUUID)) {
//     for (const product of menu.menu.products) {
//       // Check if the product is not already in the uniqueProductsArray
//       if (!uniqueProductsArray.some((p) => p.uuid === product.uuid)) {
//         uniqueProductsArray.push(product);
//       }
//     }
//   }
//   }
//   console.log(uniqueProductsArray, 'uuu')
//   return uniqueProductsArray;
// };

const getUniqueProductsForBranch = (
  branchUUID,
  menusdata,
  productsReceived
) => {
  let uniqueProductsArray = [];

  for (const menu of menusdata) {
    if (!menu?.branches?.find((excludedBranch) => {
      return excludedBranch?.uuid === branchUUID
    })) {
      for (const product of menu.menu.products) {
        const existingProductIndex = uniqueProductsArray.findIndex(
          (p) => p.uuid === product.uuid
        );

        if (existingProductIndex === -1) {
          // Product doesn't exist, add it
          uniqueProductsArray.push({
            ...product,
            productGroups: [product.productGroup]
          }
          );
        } else {
          // Product already exists, replace it with the updated one
          uniqueProductsArray[existingProductIndex] = {
            ...product,
            productGroups: uniqueProductsArray[existingProductIndex]?.productGroups.includes(product.productGroup) ?
              [...uniqueProductsArray[existingProductIndex].productGroups] :
              [...uniqueProductsArray[existingProductIndex].productGroups, product.productGroup]
          };
        }
      }
    }
  }

  // Add received products to the uniqueProductsArray
  for (const receivedProduct of productsReceived) {
    const existingProductIndex = uniqueProductsArray.findIndex(
      (p) => p.uuid === receivedProduct.uuid
    );

    if (existingProductIndex === -1) {
      // Received product doesn't exist, add it
      uniqueProductsArray.push({
        ...receivedProduct,
        productGroups: [receivedProduct.productGroup]
      })
    } else {
      // Received product already exists, replace it with the updated one
      uniqueProductsArray[existingProductIndex] = {
        ...receivedProduct,
        productGroups: uniqueProductsArray[existingProductIndex]?.productGroups.includes(receivedProduct.productGroup) ?
          [...uniqueProductsArray[existingProductIndex].productGroups] :
          [...uniqueProductsArray[existingProductIndex].productGroups, receivedProduct.productGroup]
      };
    }
  }
  //sort by order
  uniqueProductsArray = uniqueProductsArray.sort(
    (a, b) => a.order - b.order
  );
  return uniqueProductsArray;
};

// const getUniqueProductsForBranch = (branchUUID, menusdata, productsReceived) => {
//   const uniqueProductsArray = [];

//   for (const menu of menusdata) {
//     if (!menu?.branches?.includes(branchUUID)) {
//       for (const product of menu.menu.products) {
//         // Check if the product is not already in the uniqueProductsArray
//         if (!uniqueProductsArray.some((p) => p.uuid === product.uuid)) {
//           uniqueProductsArray.push(product);
//         }
//       }
//     }
//   }

//   // Add received products to the uniqueProductsArray
//   for (const receivedProduct of productsReceived) {
//     // Check if the received product is not already in the uniqueProductsArray
//     if (!uniqueProductsArray.some((p) => p.uuid === receivedProduct.uuid)) {
//       uniqueProductsArray.push(receivedProduct);
//     }
//   }

//   return uniqueProductsArray;
// };

// const getProducts = (products) => {
//   let productsArray = [];
//   products?.forEach((product) => {
//     const existingProductIndex = productsArray.findIndex((existingProduct) => existingProduct.uuid === product.uuid);

//     if (existingProductIndex === -1) {
//       productsArray.push({
//         ...product,
//         productGroups: [product.productGroup]
//       });
//     } else {
//       productsArray[existingProductIndex].productGroups.push(product.productGroup);
//     }
//   });
//   return productsArray;
// };

const getUniqueComboProducts = (initialObject, newObject) => {
  let updatedObject = { ...initialObject };

  // Iterate over newObject to check and add unique items based on the key (uuid)
  for (let key in newObject) {
    if (newObject?.hasOwnProperty(key)) {
      // if (!initialObject?.hasOwnProperty(key)) {
      // If the key does not exist in initialObject, add it to updatedObject
      updatedObject[key] = newObject[key];
      // }
    }
  }
  return updatedObject;
};

const getProducts = (products) => {
  let productsArray = [];

  products?.forEach((product) => {
    const existingProductIndex = productsArray.findIndex(
      (existingProduct) => existingProduct.uuid === product.uuid
    );

    if (existingProductIndex === -1) {
      // Product doesn't exist, add it
      productsArray.push({
        ...product,
        productGroups: [product.productGroup],
      });
    } else {
      // // Product already exists, replace it with the updated one
      // productsArray[existingProductIndex] = {
      //   ...product,
      //   productGroups: [
      //     ...productsArray[existingProductIndex].productGroups,
      //     product.productGroup,
      //   ],
      // };
      productsArray[existingProductIndex] = {
        ...product,
        productGroups: productsArray[existingProductIndex]?.productGroups.includes(product.productGroup) ?
          [...productsArray[existingProductIndex].productGroups, product.productGroup] :
          [...productsArray[existingProductIndex].productGroups],
      };
    }
  });
  //sort items by order
  productsArray = productsArray.sort(
    (a, b) => a.order - b.order
  );
  return productsArray;
};

const getUniqueIngredients = (products) => {
  let ingredientsArray = [];
  products?.forEach((product) => {
    product?.ingredients?.forEach((pIng) => {
      if (
        ingredientsArray.findIndex((obj) => {
          return obj.uuid === pIng.uuid;
        }) === -1
      ) {
        ingredientsArray.push(pIng);
      }
    });
  });
  return ingredientsArray;
};

const getUniqueTypesAndAllergies = (products) => {
  let typesAndAllergiesArray = [];
  products?.forEach((product) => {
    product?.foodTypesAndAlergies?.forEach((item) => {
      if (
        typesAndAllergiesArray.findIndex((obj) => {
          return obj.uuid === item.uuid;
        }) === -1
      ) {
        typesAndAllergiesArray.push(item);
      }
    });
  });
  return typesAndAllergiesArray;
};

function products(state = initialState, action) {
  const appSource = action?.payload?.appSource;
  const menusSelectedRestaurantState =
    appSource === "dine-in"
      ? "dineInMenusSelectedRestaurant"
      : appSource === "pay"
        ? "payMenusSelectedRestaurant"
        : appSource === "QR"
          ? "QRMenusSelectedRestaurant"
          : "menusSelectedRestaurant";
  const menusState =
    appSource === "dine-in"
      ? "dineInMenus"
      : appSource === "pay"
        ? "payMenus"
        : appSource === "QR"
          ? "QRMenus"
          : "menus";
  const menusDataState =
    appSource === "dine-in"
      ? "dineInMenusData"
      : appSource === "pay"
        ? "payMenusData"
        : appSource === "QR"
          ? "QRMenusData"
          : "menusData";
  const branchAllMenusProductsState =
    appSource === "dine-in"
      ? "dineInBranchAllMenusProducts"
      : appSource === "pay"
        ? "payBranchAllMenusProducts"
        : appSource === "QR"
          ? "QRBranchAllMenusProducts"
          : "branchAllMenusProducts";
  const comboProductsState =
    appSource === "dine-in"
      ? "dineInComboProducts"
      : appSource === "pay"
        ? "payComboProducts"
        : appSource === "QR"
          ? "QRComboProducts"
          : "comboProducts";
  const productsState =
    appSource === "dine-in"
      ? "dineInProducts"
      : appSource === "pay"
        ? "payProducts"
        : appSource === "QR"
          ? "QRProducts"
          : "products";
  const groupsState =
    appSource === "dine-in"
      ? "dineInGroups"
      : appSource === "pay"
        ? "payGroups"
        : appSource === "QR"
          ? "QRGroups"
          : "groups";
  const selectedMenuState =
    appSource === "dine-in"
      ? "dineInSelectedMenu"
      : appSource === "pay"
        ? "paySelectedMenu"
        : appSource === "QR"
          ? "QRSelectedMenu"
          : "selectedMenu";
  const ingredientsState =
    appSource === "dine-in"
      ? "dineInIngredients"
      : appSource === "pay"
        ? "payIngredients"
        : appSource === "QR"
          ? "QRIngredients"
          : "ingredients";
  const typesAndAllergiesState =
    appSource === "dine-in"
      ? "dineInTypesAndAllergies"
      : appSource === "pay"
        ? "payTypesAndAllergies"
        : appSource === "QR"
          ? "QRTypesAndAllergies"
          : "typesAndAllergies";
  const viewState =
    appSource === "dine-in"
      ? "dineInView"
      : appSource === "pay"
        ? "payView"
        : appSource === "QR"
          ? "QRView"
          : "view";
  const ingredientsFilterState =
    appSource === "QR"
      ? "QRIngredientsFilter"
      : appSource === "dine-in"
        ? "dineInIngredientsFilter"
        : appSource === "pay"
          ? "payIngredientsFilter"
          : "ingredientsFilter";
  const typesAndAllergiesFilterState =
    appSource === "QR"
      ? "QRTypesAndAllergiesFilter"
      : appSource === "dine-in"
        ? "dineInTypesAndAllergiesFilter"
        : appSource === "pay"
          ? "payTypesAndAllergiesFilter"
          : "typesAndAllergiesFilter";
  const trendingFilterState =
    appSource === "QR"
      ? "QRTrendingFilter"
      : appSource === "dine-in"
        ? "dineInTrendingFilter"
        : appSource === "pay"
          ? "payTrendingFilter"
          : "trendingFilter";
  const priceFilterState =
    appSource === "QR"
      ? "QRPriceFilter"
      : appSource === "dine-in"
        ? "dineInPriceFilter"
        : appSource === "pay"
          ? "payPriceFilter"
          : "priceFilter";
  const offersFilterState =
    appSource === "QR"
      ? "QROffersFilter"
      : appSource === "dine-in"
        ? "dineInOffersFilter"
        : appSource === "pay"
          ? "payOffersFilter"
          : "offersFilter";
  const favoritesFilterState =
    appSource === "QR"
      ? "QRFavoritesFilter"
      : appSource === "dine-in"
        ? "dineInFavoritesFilter"
        : appSource === "pay"
          ? "payFavoritesFilter"
          : "favoritesFilter";

  switch (action.type) {
    case GET_MENUS_REQUEST:
      return Object.assign({}, state, {
        getMenusLoading: state[menusState]?.length > 0 ? false : true,
      });
    case GET_MENUS_SUCCESS:
      return Object.assign({}, state, {
        getMenusLoading: false,
        [menusSelectedRestaurantState]: action?.payload?.selectedRestaurant,
        [menusState]: action.payload.menus,
      });
    case GET_MENUS_ERROR:
      return Object.assign({}, state, {
        getMenusLoading: false,
      });
    case RESET_MENUS:
      return Object.assign({}, state, {
        [menusState]: [],
        [menusDataState]: [],
        [branchAllMenusProductsState]: null,
        [comboProductsState]: null,
        [productsState]: [],
        [groupsState]: [],
        [selectedMenuState]: null,
      });

    case SET_SELECTED_MENU:
      const menuAlreadyGotten = state[menusDataState].find((menuData) => {
        return menuData?.uuid === action?.payload?.menuId
      });

      return Object.assign({}, state, {
        [selectedMenuState]: action.payload.menuId,

        [groupsState]: menuAlreadyGotten ? menuAlreadyGotten?.menu?.groups : state[groupsState],

        [productsState]: menuAlreadyGotten ? getProducts(menuAlreadyGotten?.menu?.products) : state[productsState],

        [ingredientsState]: menuAlreadyGotten ? getUniqueIngredients(menuAlreadyGotten?.menu?.products) : state[ingredientsState],

        [typesAndAllergiesState]: menuAlreadyGotten ? getUniqueTypesAndAllergies(
          menuAlreadyGotten?.menu?.products) : state[typesAndAllergiesState]
      });

    case RESET_SELECTED_MENU:
      return Object.assign({}, state, {
        [selectedMenuState]: null,
      });

    case GET_PRODUCTS_REQUEST:
      const productsAlreadyGotten = state[menusDataState].find((menuData) => {
        return menuData?.uuid === action?.payload?.menuId
      });

      return Object.assign({}, state, {
        loading: !action?.payload?.menuSelection || productsAlreadyGotten ? false : true,
      });

    case GET_PRODUCTS_SUCCESS:
      const newMenusDataState = getMenusData(
        action.payload.menuData,
        state[menusState],
        state[menusDataState]
      );
      return Object.assign({}, state, {
        loading: false,
        [groupsState]: action?.payload?.menuSelection && action?.payload?.menuId === state[selectedMenuState] ? action.payload.groups : state[groupsState],
        [menusDataState]: newMenusDataState,
        [comboProductsState]: action?.payload?.menuSelection && action?.payload?.menuId === state[selectedMenuState] ? getUniqueComboProducts(state[comboProductsState], action?.payload.comboProducts) : state[productsState],
        [productsState]: action?.payload?.menuSelection && action?.payload?.menuId === state[selectedMenuState] ? getProducts(action.payload.products) : state[productsState],
        [branchAllMenusProductsState]:
          getUniqueProductsForBranch(
            action.payload.branch,
            newMenusDataState,
            action.payload.products
          ),
        [ingredientsState]: action?.payload?.menuSelection && action?.payload?.menuId === state[selectedMenuState] ? getUniqueIngredients(action.payload.products) : state[ingredientsState],
        [typesAndAllergiesState]: action?.payload?.menuSelection && action?.payload?.menuId === state[selectedMenuState] ? getUniqueTypesAndAllergies(
          action.payload.products) : state[typesAndAllergiesState]
        ,
      });
    case GET_PRODUCTS_ERROR:
      return Object.assign({}, state, {
        loading: false,
      });
    case CHANGE_PRODUCTS_VIEW:
      return Object.assign({}, state, {
        [viewState]: action?.payload?.pView,
      });
    case SET_INGREDIENTS_FILTER:
      return Object.assign({}, state, {
        [ingredientsFilterState]: action.payload.filter,
      });
    case SET_TYPES_AND_ALLERGIES_FILTER:
      return Object.assign({}, state, {
        [typesAndAllergiesFilterState]: action.payload.filter,
      });
    case SET_TRENDING_FILTER:
      return Object.assign({}, state, {
        [trendingFilterState]: action.payload.filter,
      });
    case SET_OFFERS_FILTER:
      return Object.assign({}, state, {
        [offersFilterState]: action.payload.filter,
      });
    case SET_FAVORITES_FILTER:
      return Object.assign({}, state, {
        [favoritesFilterState]: action.payload.filter,
      });
    case SET_PRICE_FILTER:
      return Object.assign({}, state, {
        [priceFilterState]: action.payload.filter,
      });
    case CLEAR_FILTERS:
      return Object.assign({}, state, {
        [trendingFilterState]: false,
        [offersFilterState]: false,
        [favoritesFilterState]: false,
        [ingredientsFilterState]: [],
        [typesAndAllergiesFilterState]: [],
        [priceFilterState]: [],
      });
    case CLEAR_ALL_PRODUCTS_DATA:
      return initialState;
    default:
      return state;
  }
}

export default products;
