export const SET_REFERENCE = "SET_REFERENCE";

export const actions = {
    setReference: (ref) => (dispatch) => {
        dispatch({
            type: SET_REFERENCE,
            payload: {
                reference: ref
            }
        })
    }
}