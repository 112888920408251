import { notify } from "../../utils/notify";
import {
  getOrders,
  getOrder,
  sendOrder,
  cancelOrder,
} from "../../config/config";

import { LOGOUT } from "./AuthActions";

export const GET_ORDERS_REQUEST = "GET_ORDERS_REQUEST";
export const GET_ORDERS_SUCCESS = "GET_ORDERS_SUCCESS";
export const GET_ORDERS_ERROR = "GET_ORDERS_ERROR";

export const GET_ORDER_REQUEST = "GET_ORDER_REQUEST";
export const GET_ORDER_SUCCESS = "GET_ORDER_SUCCESS";
export const GET_ORDER_ERROR = "GET_ORDER_ERROR";

export const SEND_ORDER_REQUEST = "SEND_ORDER_REQUEST";
export const SEND_ORDER_SUCCESS = "SEND_ORDER_SUCCESS";
export const SEND_ORDER_ERROR = "SEND_ORDER_ERROR";

export const CANCEL_ORDER_REQUEST = "CANCEL_ORDER_REQUEST";
export const CANCEL_ORDER_SUCCESS = "CANCEL_ORDER_SUCCESS";
export const CANCEL_ORDER_ERROR = "CANCEL_ORDER_ERROR";

export const RESET_ORDER = "RESET_ORDER";

export const SET_ORDERS_SELECTED_GROUP = "SET_ORDERS_SELECTED_GROUP";

export const CLEAR_SEND_ORDER_LOADING = "CLEAR_SEND_ORDER_LOADING";

export const CLEAR_ALL_ORDERS_DATA = "CLEAR_ALL_ORDERS_DATA";

export const actions = {
  getOrders: (restaurantId) => async (dispatch) => {
    dispatch({
      type: GET_ORDERS_REQUEST,
      payload: {
        ruuid: restaurantId
      }
    });

    try {
      const response = await getOrders(restaurantId);
      if (response.status === 200) {
        dispatch({
          type: GET_ORDERS_SUCCESS,
          payload: {
            data: response?.data?.orders,
            ruuid: restaurantId
          },
        });
      } else {
        if (response.status === 401) {
          dispatch({ type: LOGOUT });
        }
        dispatch({
          type: GET_ORDERS_ERROR,
          ruuid: restaurantId
        });

        notify.error(
          response?.data?.message || response?.message || "Get Orders error"
        );
      }
    } catch (error) {
      dispatch({
        type: GET_ORDERS_ERROR,
      });

      notify.error("Get Orders error");
    }
  },

  getOrder: (restaurantId, branchId, orderId) => async (dispatch) => {
    dispatch({ type: GET_ORDER_REQUEST });

    try {
      const response = await getOrder(restaurantId, branchId, orderId);
      if (response.status === 200) {
        dispatch({
          type: GET_ORDER_SUCCESS,
          payload: {
            data: response?.data?.order,
          },
        });
      } else {
        if (response.status === 401) {
          dispatch({ type: LOGOUT });
        }
        dispatch({
          type: GET_ORDER_ERROR,
        });

        notify.error(
          response?.data?.message || response?.message || "Get Order error"
        );
      }
    } catch (error) {
      dispatch({
        type: GET_ORDER_ERROR,
      });

      notify.error("Get Order error");
    }
  },

  sendOrder:
    (restaurantId, branchId, data, onSuccess, onError) => async (dispatch) => {
      dispatch({ type: SEND_ORDER_REQUEST });
      try {
        const response = await sendOrder(restaurantId, branchId, data);
        console.log(response);
        if (response.status === 200) {
          dispatch({
            type: SEND_ORDER_SUCCESS,
          });
          console.log(response)
          onSuccess(response?.data?.uuid);
        } else {
          if (response.status === 401) {
            dispatch({ type: LOGOUT });
          }
          dispatch({
            type: SEND_ORDER_ERROR,
          });
          onError(
            response?.data?.message || response?.message || "Send Order error", response?.status
          );
        }
      } catch (error) {
        dispatch({
          type: SEND_ORDER_ERROR,
        });
        onError("Send Order error");
      }
    },
  cancelOrder: (restaurantId, branchId, orderId, onSuccess) => async (dispatch) => {
    dispatch({ type: CANCEL_ORDER_REQUEST });
    try {
      const response = await cancelOrder(restaurantId, branchId, orderId);
      if (response.status === 200) {
        if (onSuccess) {
          onSuccess();
        }
        dispatch({
          type: CANCEL_ORDER_SUCCESS,
          payload: {
            orderId: orderId,
          },
        });
      } else {
        if (response.status === 401) {
          dispatch({ type: LOGOUT });
          dispatch({
            type: CANCEL_ORDER_ERROR,
          });
        }
        dispatch({
          type: CANCEL_ORDER_ERROR,
        });
        notify.error(response?.data?.message || "Cancel Order error");
      }
    } catch (error) {
      dispatch({
        type: CANCEL_ORDER_ERROR,
      });
      notify.error("Cancel Order error");
    }
  },
  resetOrder: () => (dispatch) => {
    dispatch({ type: RESET_ORDER });
  },
  setOrdersSelectedGroup: (value) => (dispatch) => {
    dispatch({
      type: SET_ORDERS_SELECTED_GROUP,
      payload: {
        value: value
      }
    });
  },
  clearSendOrderLoading: () => (dispatch) => {
    dispatch({
      type: CLEAR_SEND_ORDER_LOADING
    })
  },
  clearAllOrdersData: () => (dispatch) => {
    dispatch({
      type: CLEAR_ALL_ORDERS_DATA

    })
  }
};
