import { combineReducers } from "redux";

// import reducers
import organization from "./OrganizationReducer";
import translation from "./TranslationReducer";
import language from "./LanguageReducer";
import designs from "./DesignsReducer";
import restaurants from "./RestaurantsReducer";
import notifications from "./NotificationsReducer";
import affiliateLinks from "./AffiliateLinksReducer";
import deliveryMethod from "./DeliveryMethodReducer";
import cart from "./CartReducer";
import reservation from "./ReservationsReducer";
import popularItems from "./PopularItemsReducer";
import products from "./ProductsReducer";
import discounts from "./DiscountsReducer";
import promotions from "./PromotionsReducer";
import media from "./MediaReducer";
import messages from "./MessagesReducer";
import events from "./EventsReducer";
import feedback from "./FeedbackReducer";
import gallery from "./GalleryReducer";
import favorites from "./FavoritesReducer";
import appSource from "./AppSourceReducer";
import user from "./UserReducer";
import auth from "./AuthReducer";
import addresses from "./AddressesReducer";
import loyalty from "./LoyaltyReducer";
import orders from "./OrdersReducer";

// will combine reducer
const reducers = combineReducers({
  organization,
  translation,
  language,
  designs,
  restaurants,
  notifications,
  affiliateLinks,
  deliveryMethod,
  cart,
  reservation,
  popularItems,
  products,
  discounts,
  promotions,
  events,
  media,
  messages,
  feedback,
  gallery,
  favorites,
  appSource,
  user,
  auth,
  addresses,
  loyalty,
  orders,
});

export default reducers;
