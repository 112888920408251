import {
    SET_FCM_TOKEN,

    REGISTRATION_REQUEST,
    REGISTRATION_SUCCESS,
    REGISTRATION_ERROR
} from '../actions/NotificationsActions'

const initialState = {
    token: ''
}

function notifications(state = initialState, action) {
    switch (action.type) {
        case SET_FCM_TOKEN:
            return Object.assign({}, state, {
                token: action.payload
            })
        default:
            return state
    }
}
export default notifications