import {
  GET_TRANSLATION_REQUEST,
  GET_TRANSLATION_SUCCESS,
  GET_TRANSLATION_ERROR,
} from "../actions/TranslationActions";

const initialState = {
  loading: false,
  translation: [],
  supportedLanguages: [],
};

function translation(state = initialState, action) {
  switch (action.type) {
    case GET_TRANSLATION_REQUEST:
      return Object.assign({}, state, {
        loading: true,
      });
    case GET_TRANSLATION_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        supportedLanguages: action.payload.supportedLanguages,
        translation: action.payload.translation,
      });
    case GET_TRANSLATION_ERROR:
      return Object.assign({}, state, {
        loading: false,
      });
    default:
      return state;
  }
}

export default translation;
