import { notify } from "../../utils/notify";
import { getLoyalty } from "../../config/config";

export const GET_LOYALTY_INFO_REQUEST = "GET_LOYALTY_INFO_REQUEST";
export const GET_LOYALTY_INFO_SUCCESS = "GET_LOYALTY_INFO_SUCCESS";
export const GET_LOYALTY_INFO_ERROR = "GET_LOYALTY_INFO_ERROR";
export const CLEAR_ALL_LOYALTY_DATA = "CLEAR_ALL_LOYALTY_DATA";

export const actions = {
    //GET LOYALTY INFO
    getLoyaltyInfo: (restaurantId, appSource) => async (dispatch) => {
        dispatch({
            type: GET_LOYALTY_INFO_REQUEST
        });
        await getLoyalty(restaurantId).then((res) => {
            if (res?.status === 200) {
                dispatch({
                    type: GET_LOYALTY_INFO_SUCCESS,
                    payload: {
                        loyalty: res?.data?.loyality,
                        appSource: appSource
                    },
                });
            } else {
                notify.error(res?.data?.message || "Get Loyalty error");
                dispatch({
                    type: GET_LOYALTY_INFO_ERROR,
                    appSource: appSource
                });
            }
        });
    },
    //CLEAR ALL DATA
    clearAllLoyaltyData: () => (dispatch) => {
        dispatch({
            type: CLEAR_ALL_LOYALTY_DATA
        })
    }
}

export default actions;
