export const ENV = process.env.REACT_APP_ENV;
// export const CLOUD9_API =
//   ENV === 'development'
//     ? 'http://localhost:9900'
//     : 'https://api.gain-intel.com/api';

export const CLOUD9_API =  process.env.REACT_APP_API_URL || 'https://api.gain-intel.com/api';

export const MESSAGES_API = process.env.REACT_APP_MESSAGING_URL || 'https://api.gain-intel.com/messaging';

export const IMAGES_PATH = process.env.REACT_APP_API_URL ?  `${process.env.REACT_APP_API_URL}/static/uploads/` : 'https://api.gain-intel.com/api/static/uploads/';
