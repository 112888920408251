import {
    GET_LOYALTY_INFO_REQUEST,
    GET_LOYALTY_INFO_SUCCESS,
    GET_LOYALTY_INFO_ERROR,
    CLEAR_ALL_LOYALTY_DATA
} from "../actions/LoyaltyActions";

const initialState = {
    getLoyaltyInfoLoading: false,
    loyaltyInfo: [],
    dineInLoyaltyInfo: [],
    payLoyaltyInfo: [],
    QRLoyaltyInfo: []
};

function loyalty(state = initialState, action) {
    const appSource = action?.payload?.appSource;
    const loyaltyInfoState =
        appSource === "dine-in"
            ? "dineInLoyaltyInfo"
            : appSource === "pay"
                ? "payLoyaltyInfo"
                : appSource === "QR"
                    ? "QRLoyaltyInfo"
                    : "loyaltyInfo";
    switch (action.type) {
        case GET_LOYALTY_INFO_REQUEST:
            return Object.assign({}, state, {
                getLoyaltyInfoLoading: true,
            });
        case GET_LOYALTY_INFO_SUCCESS:
            return Object.assign({}, state, {
                getLoyaltyInfoLoading: false,
                [loyaltyInfoState]: action.payload.loyalty,
            });
        case GET_LOYALTY_INFO_ERROR:
            return Object.assign({}, state, {
                getLoyaltyInfoLoading: false,
                [loyaltyInfoState]: []
            });
        case CLEAR_ALL_LOYALTY_DATA:
            return initialState;
        default:
            return state;
    }
}

export default loyalty;
