import { getMessages, sendMessage, getUnreadMessages } from "../../config/config";
import { LOGOUT } from "./AuthActions";

export const GET_MESSAGES_REQUEST = "GET_MESSAGES_REQUEST";
export const GET_MESSAGES_SUCCESS = "GET_MESSAGES_SUCCESS";
export const GET_MESSAGES_ERROR = "GET_MESSAGES_ERROR";
export const FETCH_MORE_MESSAGES_REQUEST = "FETCH_MORE_MESSAGES_REQUEST";
export const FETCH_MORE_MESSAGES_SUCCESS = "FETCH_MORE_MESSAGES_SUCCESS";
export const FETCH_MORE_MESSAGES_ERROR = "FETCH_MORE_MESSAGES_ERROR";
export const SEND_MESSAGE_REQUEST = "SEND_MESSAGE_REQUEST";
export const SEND_MESSAGE_SUCCESS = "SEND_MESSAGE_SUCCESS";
export const SEND_MESSAGE_ERROR = "SEND_MESSAGE_ERROR";
export const GET_UNREAD_MESSAGES_REQUEST = "GET_UNREAD_MESSAGES_REQUEST";
export const GET_UNREAD_MESSAGES_SUCCESS = "GET_UNREAD_MESSAGES_SUCCESS";
export const GET_UNREAD_MESSAGES_ERROR = "GET_UNREAD_MESSAGES_ERROR";
export const SET_MESSAGES_USER_SELECTED_BRANCH = "SET_MESSAGES_USER_SELECTED_BRANCH";
export const CLEAR_MESSAGES_USER_SELECTED_BRANCH = "CLEAR_MESSAGES_USER_SELECTED_BRANCH";
export const CLEAR_ALL_MESSAGES_DATA = "CLEAR_ALL_MESSAGES_DATA";

export const actions = {
    getMessages:
        (
            ruuid,
            buuid,
            page,
            limit,
            appSource,
        ) =>
            async (dispatch) => {
                dispatch({
                    type: GET_MESSAGES_REQUEST,
                    payload: {
                        ruuid: ruuid,
                        buuid: buuid
                    }
                });
                try {
                    const res = await getMessages(ruuid, buuid, page, limit);
                    if (res.status === 200) {
                        dispatch({
                            type: GET_MESSAGES_SUCCESS,
                            payload: {
                                ruuid: ruuid,
                                buuid: buuid,
                                chatDetails: res?.data?.chatDetails[0]?.chat,
                                messages: res?.data?.messages,
                                totalMessages: res?.data?.totalMessages,
                                totalPages: 1,
                                //current page
                                pageNumber: res?.data?.pageNumber,
                                appSource: appSource
                            }

                        });

                        dispatch(actions.getUnreadMessages(ruuid));

                    } else if (res.status === 404) {
                        dispatch({
                            type: GET_MESSAGES_SUCCESS,
                            payload: {
                                ruuid: ruuid,
                                buuid: buuid,
                                chatDetails: null,
                                messages: res?.data?.messages,
                                totalMessages: [],
                                totalPages: 0,
                                //current page
                                pageNumber: 0,
                                appSource: appSource
                            }
                        });
                    }
                    else {
                        if (res.status === 401) {
                            dispatch({ type: LOGOUT });
                        }
                        dispatch({
                            type: GET_MESSAGES_ERROR,
                            payload: {
                                ruuid: ruuid,
                                buuid: buuid,
                            }
                        });
                    }
                } catch (error) {
                    dispatch({
                        type: GET_MESSAGES_ERROR,
                        payload: {
                            ruuid: ruuid,
                            buuid: buuid,
                        }
                    });
                }
            },
    fetchMoreMessages:
        (ruuid, buuid, page, limit) => async (dispatch) => {
            dispatch({
                type: FETCH_MORE_MESSAGES_REQUEST
            })
            try {
                const res = await getMessages(ruuid, buuid, page, limit);
                if (res.status === 200) {
                    dispatch({
                        type: FETCH_MORE_MESSAGES_SUCCESS,
                        payload: {
                            ruuid: ruuid,
                            buuid: buuid,
                            chatDetails: res?.data?.chatDetails[0]?.chat,
                            newMessages: res?.data?.messages,
                            totalMessages: res?.data?.totalMessages,
                            totalPages: res?.data?.totalPages,
                            //current page
                            pageNumber: res?.data?.pageNumber,
                        }

                    });
                } else {
                    if (res.status === 401) {
                        dispatch({ type: LOGOUT });
                    }
                    dispatch({
                        type: FETCH_MORE_MESSAGES_ERROR,
                    });
                }
            } catch (error) {
                dispatch({
                    type: FETCH_MORE_MESSAGES_ERROR,
                });
            }
        },
    sendMessage:
        (ruuid, buuid, messages, user, appSource) => async (dispatch) => {
            dispatch({
                type: SEND_MESSAGE_REQUEST,
                payload: {
                    ruuid: ruuid,
                    buuid: buuid,
                    messages: messages,
                    user: user,
                    appSource: appSource,
                },
            });
            try {
                const res = await sendMessage(ruuid, buuid, messages);
                if (res.status === 200) {
                    dispatch({
                        type: SEND_MESSAGE_SUCCESS,
                        payload: {
                            ruuid: ruuid,
                            buuid: buuid,
                            messages: messages,
                            user: user,
                            appSource: appSource,
                        },
                    });
                } else {
                    if (res.status === 401) {
                        dispatch({ type: LOGOUT });
                    }
                    dispatch({
                        type: SEND_MESSAGE_ERROR,
                        payload: {
                            ruuid: ruuid,
                            buuid: buuid,
                            messages: messages,
                            appSource: appSource,
                        },
                    });
                }
            } catch (error) {
                dispatch({
                    type: SEND_MESSAGE_ERROR,
                    payload: {
                        appSource: appSource,
                    },
                });
            }
        },
    getUnreadMessages:
        (
            ruuid
        ) =>
            async (dispatch) => {
                dispatch({
                    type: GET_UNREAD_MESSAGES_REQUEST,
                    payload: {
                        ruuid: ruuid
                    }
                });
                try {
                    const res = await getUnreadMessages(ruuid);
                    if (res.status === 200) {
                        dispatch({
                            type: GET_UNREAD_MESSAGES_SUCCESS,
                            payload: {
                                ruuid: ruuid,
                                unreadMessagesNumber: res?.data?.totalUnread,
                                unreadMessagesChatsDetails: res?.data?.chatDetails
                            }
                        });
                    }
                    else {
                        if (res.status === 401) {
                            dispatch({ type: LOGOUT });
                            return;
                        }
                        dispatch({
                            type: GET_UNREAD_MESSAGES_ERROR,
                            payload: {
                                ruuid: ruuid
                            }
                        });
                    }
                } catch (error) {
                    dispatch({
                        type: GET_UNREAD_MESSAGES_ERROR,
                        payload: {
                            ruuid: ruuid
                        }
                    });
                }
            },
    setMessagesUserSelectedBranch: (buuid) => (dispatch) => {
        dispatch({
            type: SET_MESSAGES_USER_SELECTED_BRANCH,
            payload: {
                buuid: buuid
            }
        });
    },
    clearMessagesUserSelectedBranch: () => (dispatch) => {
        dispatch({ type: CLEAR_MESSAGES_USER_SELECTED_BRANCH });
    },
    clearAllMessagesData: () => (dispatch) => {
        dispatch({ type: CLEAR_ALL_MESSAGES_DATA });
    },
};
