export const ADD_TO_CART = "ADD_TO_CART";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const UPDATE_CART = "UPDATE_CART";
export const INCREMENT_CART_ITEM = "INCREMENT_CART_ITEM";
export const DECREMENT_CART_ITEM = "DECREMENT_CART_ITEM";
export const SET_CART_DETAILS = "SET_CART_DETAILS";
export const UPDATE_CART_DETAIL = "UPDATE_CART_DETAIL";
export const UPDATE_CART_ORDER_INSTRUCTIONS = "UPDATE_CART_ORDER_INSTRUCTIONS";
export const CLEAR_CART = "CLEAR_CART";
export const PREPARE_CHECKOUT = "PREPARE_CHECKOUT";
export const UPDATE_CHECKOUT_DATA = "UPDATE_CHECKOUT_DATA";
export const SET_DINE_IN_ORDER_PAID = "SET_DINE_IN_ORDER_PAID";
export const SET_PAY_ORDER_PAID = "SET_PAY_ORDER_PAID";
export const SET_COPIED_PROMOCODE = "SET_COPIED_PROMOCODE";
export const ADD_CART_DRAFT = "ADD_CART_DRAFT";
export const USE_CART_DRAFT_STARTED = "USE_CART_DRAFT_STARTED";
export const USE_CART_DRAFT_ENDED = "USE_CART_DRAFT_ENDED";
export const REMOVE_CART_DRAFT = "REMOVE-CART_DRAFT";
export const SET_CART_DRAFT_USED = "SET_CART_DRAFT_USED";
export const SET_GOT_USED_MENUS = "SET_GOT_USED_MENUS";
export const SET_ORIGINAL_POINTS = "SET_ORIGINAL_POINTS";
export const UPDATE_POINTS_USED = "UPDATE_POINTS_USED";
export const REMOVE_POINTS_USED = "REMOVE_POINTS_USED";
export const RESET_POINTS_DATA = "RESET_POINTS_DATA";

export const actions = {
  //ADD TO CART
  addToCart: (cartItem, appSource) => (dispatch) => {
    dispatch({
      type: ADD_TO_CART,
      payload: {
        cartItem: cartItem,
        appSource: appSource,
      },
    });
    // navigate('/');
  },

  //REMOVE FROM CART
  removeFromCart: (additionTime, appSource) => (dispatch) => {
    dispatch({
      type: REMOVE_FROM_CART,
      payload: {
        additionTime: additionTime,
        appSource: appSource,
      },
    });
  },

  //UPDATE CART
  updateCart: (id, additionTime, cartData, appSource) => (dispatch) => {
    dispatch({
      type: UPDATE_CART,
      payload: {
        id: id,
        additionTime: additionTime,
        cartData: cartData,
        appSource: appSource,
      },
    });
  },

  //INCREMENT CART ITEM
  incrementCartItem: (additionTime, appSource) => (dispatch) => {
    dispatch({
      type: INCREMENT_CART_ITEM,
      payload: {
        additionTime: additionTime,
        appSource: appSource,
      },
    });
  },
  //DECREMENT CART ITEM
  decrementCartItem: (additionTime, appSource) => (dispatch) => {
    dispatch({
      type: DECREMENT_CART_ITEM,
      payload: {
        additionTime: additionTime,
        appSource: appSource,
      },
    });
  },
  // SET CART DETAILS
  setCartDetails: (restaurant, branch, deliveryMethod) => (dispatch) => {
    dispatch({
      type: SET_CART_DETAILS,
      payload: {
        restaurant: restaurant,
        branch: branch,
        deliveryMethod: deliveryMethod,
      },
    });
  },
  // UPDATE CART DETAIL
  updateCartDetail: (detail, value) => (dispatch) => {
    dispatch({ type: UPDATE_CART_DETAIL, payload: { detail, value } });
  },
  // UPDATE CART ORDER INSTRUCTIONS
  updateCartOrderInstructions: (value, appSource) => (dispatch) => {
    dispatch({
      type: UPDATE_CART_ORDER_INSTRUCTIONS,
      payload: { value, appSource },
    });
  },
  // CLEAR CART
  clearCart: (appSource) => (dispatch) => {
    dispatch({ type: CLEAR_CART, payload: { appSource: appSource } });
  },
  //PREPARE CHECKOUT
  prepareCheckout: (orderData, appSource) => (dispatch) => {
    dispatch({
      type: PREPARE_CHECKOUT,
      payload: {
        orderData: orderData,
        appSource: appSource,
      },
    });
    // navigate("/checkout");
  },
  updateCheckoutData: (field, value) => (dispatch) => {
    dispatch({
      type: UPDATE_CHECKOUT_DATA,
      payload: {
        field: field,
        value: value,
      },
    });
  },
  setDineInOrderPaid: (value) => (dispatch) => {
    dispatch({
      type: SET_DINE_IN_ORDER_PAID,
      payload: {
        value: value,
      },
    });
  },
  setPayOrderPaid: (value) => (dispatch) => {
    dispatch({
      type: SET_PAY_ORDER_PAID,
      payload: {
        value: value,
      },
    });
  },
  //SET COPIED PROMOCODE
  setCopiedPromocode: (value, appSource) => (dispatch) => {
    dispatch({
      type: SET_COPIED_PROMOCODE,
      payload: {
        value: value,
        appSource: appSource,
      },
    });
  },
  //ADD CART DRAFT
  addCartDraft: (ruuid, currentAddress, deliveryMethod) => (dispatch) => {
    dispatch({
      type: ADD_CART_DRAFT,
      payload: {
        ruuid: ruuid,
        currentAddress: currentAddress,
        deliveryMethod: deliveryMethod
      }
    })
  },
  //REMOVE CART DRAFT
  removeCartDraft: (ruuid) => (dispatch) => {
    dispatch({
      type: REMOVE_CART_DRAFT,
      payload: {
        ruuid: ruuid,
      }
    })
  },
  //USE CART DRAFT START
  cartDraftUseStart: () => (dispatch) => {
    dispatch({
      type: USE_CART_DRAFT_STARTED,
    })
  },
  //USE CART DRAFT END
  cartDraftUseEnd: (ruuid) => (dispatch) => {
    dispatch({
      type: USE_CART_DRAFT_ENDED,
      payload: {
        ruuid: ruuid
      }
    })
  },
  //SET CART DRAFT AS USED
  setCartDraftUsed: (ruuid) => (dispatch) => {
    dispatch({
      type: SET_CART_DRAFT_USED,
      payload: {
        ruuid: ruuid
      }
    })
  },
  //SET GOT USED MENUS
  setGotUsedMenus: (value) => (dispatch) => {
    dispatch({
      type: SET_GOT_USED_MENUS,
      payload: {
        value: value
      }
    })
  },
  //SET ORIGINAL POINTS
  setOriginalPoints: (value, appSource) => (dispatch) => {
    dispatch({
      type: SET_ORIGINAL_POINTS,
      payload: {
        value: value,
        appSource: appSource
      }
    })
  },
  //UPDATE POINTS USED
  updatePointsUsed: (additionTime, value, appSource) => (dispatch) => {
    dispatch({
      type: UPDATE_POINTS_USED,
      payload: {
        additionTime: additionTime,
        value: value,
        appSource: appSource
      }
    })
  },
  //REMOVE POINTS USED
  removePointsUsed: (additionTime, appSource) => (dispatch) => {
    dispatch({
      type: REMOVE_POINTS_USED,
      payload: {
        additionTime: additionTime,
        appSource: appSource
      }
    })
  },
  //RESET POINTS DATA
  resetPointsData: (appSource) => (dispatch) => {
    dispatch({
      type: RESET_POINTS_DATA,
      payload: {
        appSource: appSource
      }
    })
  }
};

export default actions;