// import { useEffect, useState } from "react";
// import { createTheme, ThemeProvider } from "@mui/material/styles";
// import { getComputedStyle } from "dom-helpers";
// import { store } from "./index";
// import rtlPlugin from 'stylis-plugin-rtl';
// import { CacheProvider } from '@emotion/react';
// import createCache from '@emotion/cache';

// const ThemeWrapper = ({ children }) => {
//   const [language, setLanguage] = useState(store.getState().language.language);
//   const [theme, setTheme] = useState(null);

//   useEffect(() => {
//     const unsubscribe = store.subscribe(() => {
//       const { language } = store.getState();
//       if (language !== store.getState().language.language) {
//         setLanguage(language);
//       }
//     });

//     // Create and set the theme dynamically when the language changes
//     const createDynamicTheme = () => {
//       const root = document.querySelector(":root");
//       const mainColor = getComputedStyle(root).getPropertyValue("--main-color").trim();
//       const secondaryColor = getComputedStyle(root).getPropertyValue("--secondary-color");
//       const backgroundColor = getComputedStyle(root).getPropertyValue("--background-color");
//       const textColorOverBackgroundColor = getComputedStyle(root).getPropertyValue("--text-color-over-background-color")
//       const textColorOversecondaryColor = getComputedStyle(root).getPropertyValue("--text-color-over-secondary-color");
//       const lightTextColorOversecondaryColor = getComputedStyle(root).getPropertyValue("--light-text-color-over-secondary-color");
//       const regularFont = getComputedStyle(root).getPropertyValue("--font-regular");
//       const errorColor = getComputedStyle(root).getPropertyValue("--input-border-error-color").trim();

//       const newTheme = createTheme({
//         direction: language?.language === "arabic" ? "rtl" : "ltr",
//         typography: {
//           fontFamily: regularFont,
//         },
//         palette: {
//           primary: {
//             main: mainColor,
//           },
//           text: {
//             primary: textColorOverBackgroundColor,
//             secondary: textColorOversecondaryColor,
//           },
//           background: {
//             default: backgroundColor,
//             paper: secondaryColor,
//           },
//           divider: "rgba(145, 158, 171, 0.24)",
//           box: "#b975e0",
//           error: {
//             main: errorColor,
//           },
//         },
//         components: {
//           MuiOutlinedInput: {
//             styleOverrides: {
//               root: {
//                 backgroundColor: `${secondaryColor} !important`,
//                 color: textColorOversecondaryColor,
//               },
//               input: {
//                 "&::placeholder": {
//                   color: `${lightTextColorOversecondaryColor} !important`,
//                   border: "1px solid red"
//                 },
//               },
//             },
//           },
//           MuiInputBase: {
//             styleOverrides: {
//               input: {
//                 "&::placeholder": {
//                   color: `${lightTextColorOversecondaryColor} !important`,
//                   border: "1px solid red"
//                 },
//               },
//             },
//           },
//           MuiFormHelperText: {
//             styleOverrides: {
//               root: {
//                 fontWeight: "bold",
//               },
//             },
//           },
//         },
//       });

//       setTheme(newTheme);
//     };

//     createDynamicTheme(); // Initial theme creation

//     return () => {
//       unsubscribe();
//     };
//   }, [language]);

//   if (!theme) {
//     return null; // Render nothing until the theme is created
//   }

//   return (
//     <ThemeProvider theme={theme}>
//       {language?.language === "arabic" ? (
//         <CacheProvider value={createCache({ key: 'rtl', stylisPlugins: [rtlPlugin] })}>
//           {children}
//         </CacheProvider>
//       ) : (
//         children
//       )}
//     </ThemeProvider>
//   );
// };

// export default ThemeWrapper;

import { useEffect, useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { getComputedStyle } from "dom-helpers";
import { store } from "./layout";
import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';


const ThemeWrapper = ({ children }) => {
  // Initialize language state properly
  const initialLanguage = store.getState().language?.language || ''; // Provide a default value if language is undefined
  const initialSelectedRestaurant = store.getState().restaurants?.selectedRestaurant;

  const [language, setLanguage] = useState(initialLanguage);
  const [selectedRestaurant, setSelectedRestaurant] = useState(initialSelectedRestaurant);
  const [isLoading, setIsLoading] = useState(true);


  const [theme, setTheme] = useState(null);

  useEffect(() => {
    const unsubscribe = store.subscribe(() => {
      const { language, restaurants } = store.getState();
      if (language !== store.getState().language.language) {
        setLanguage(language?.language || ''); // Handle potential undefined language
      }
      // if (selectedRestaurant !== restaurants.selectedRestaurant) {
      //   setTimeout(() => {
      //     setSelectedRestaurant(restaurants.selectedRestaurant);
      //   }, 100);
      // }
    });

    const observer = new MutationObserver(() => {
      createDynamicTheme();
    });

    observer.observe(document.documentElement, { attributes: true, attributeFilter: ['style'] });

    // Create and set the theme dynamically when the language changes
    const createDynamicTheme = () => {
      const root = document.querySelector(":root");
      // const mainColor = getComputedStyle(root).getPropertyValue("--main-color").trim();
      const secondaryColor = getComputedStyle(root).getPropertyValue("--secondary-color");
      const backgroundColor = getComputedStyle(root).getPropertyValue("--background-color");
      const textColorOverBackgroundColor = getComputedStyle(root).getPropertyValue("--text-color-over-background-color")
      const textColorOversecondaryColor = getComputedStyle(root).getPropertyValue("--text-color-over-secondary-color");
      const lightTextColorOversecondaryColor = getComputedStyle(root).getPropertyValue("--light-text-color-over-secondary-color");
      // const regularFont = getComputedStyle(root).getPropertyValue("--font-regular");
      // const errorColor = getComputedStyle(root).getPropertyValue("--input-border-error-color").trim();
      const mainFont = getComputedStyle(root).getPropertyValue("--main-font");
      const mainColor = getComputedStyle(root).getPropertyValue("--main-color").trim() || "#1976d2"; 
      const regularFont = getComputedStyle(root).getPropertyValue("--font-regular") || "'Roboto', sans-serif"; 
      const errorColor = getComputedStyle(root).getPropertyValue("--input-border-error-color").trim() || "#f44336"; 

      const newTheme = createTheme({
        direction: language === "arabic" ? "rtl" : "ltr", // Simplify direction check
        typography: {
          fontFamily: mainFont,
        },
        palette: {
          primary: {
            main: mainColor,
          },
          text: {
            primary: textColorOversecondaryColor,
            secondary: textColorOverBackgroundColor,
          },
          background: {
            default: backgroundColor,
            paper: secondaryColor,
          },

          divider: "rgba(145, 158, 171, 0.24)",
          box: "#b975e0",
          error: {
            main: errorColor,
          },
        },
        // components: {
        //   MuiOutlinedInput: {
        //     styleOverrides: {
        //       root: {
        //         backgroundColor: `${secondaryColor}`,
        //         color: textColorOversecondaryColor,
        //       },
        //       input: {
        //         "&::placeholder": {
        //           color: `${lightTextColorOversecondaryColor} !important`,
        //           border: "1px solid red"
        //         },
        //       },
        //     },
        //   },

        //   MuiInputBase: {
        //     styleOverrides: {
        //       input: {
        //         "&::placeholder": {
        //           color: `${lightTextColorOversecondaryColor} !important`,
        //           border: "1px solid red"
        //         },
        //       },
        //     },
        //   },
        //   MuiFormHelperText: {
        //     styleOverrides: {
        //       root: {
        //         fontWeight: "bold",
        //       },
        //     },
        //   },
        // },
        components: {
          MuiOutlinedInput: {
            styleOverrides: {
              root: {
                backgroundColor: `${secondaryColor}`,
                color: textColorOversecondaryColor,
                borderRadius: "12px",
              },
            },
          },
          MuiFormHelperText: {
            styleOverrides: {
              root: {
                font: "var(--font-regular)",
                fontSize: "var(--font-eleven)"
              },
            },
          },
          MuiSelect: {
            styleOverrides: {
              select: {
                font: regularFont
              },
              icon: {
                color: textColorOversecondaryColor,
              },
              '.MuiSelect-iconOpen': {
                color: textColorOversecondaryColor,
              },
            },
          },
          MuiInputLabel: {
            styleOverrides: {
              root: {
                fontFamily: mainFont === "Segoe UI" ? "Segoe UI Italic" : mainFont,
                fontSize: "var(--font-fourteen)",
                fontStyle: mainFont === "Segoe UI" ? "normal" : "italic",
                color: "var(--light-text-color-over-secondary-color)"
              },
            },
          },
          //level stepper styles
          MuiStepIcon: {
            styleOverrides: {
              root: {
                width: '1.7rem',
                height: '1.7rem',
                color: 'var(--disabled-color)',
                '&.Mui-completed': {
                  color: 'var(--main-color)',
                },
                '&.Mui-active': {
                  color: 'var(--disabled-color)',
                },
                '& .MuiStepIcon-text': {
                  fill: 'transparent',
                },
              },
            },
          },
          MuiStepLabel: {
            styleOverrides: {
              label: {
                '&.Mui-completed.MuiStepLabel-alternativeLabel': {
                  color: 'grey.500', // Text color for completed steps
                },
                '&.Mui-active.MuiStepLabel-alternativeLabel': {
                  color: 'var(--main-color)', // Text color for active steps
                },
              },
              labelContainer: {
                '& .MuiStepLabel-label': {
                  '&.Mui-completed': {
                    color: 'var(--main-color)',
                    // Text color for completed steps
                  },
                  '&.Mui-active': {
                    color: 'var(--main-color)', // Text color for active steps
                  },
                  '&:not(.Mui-completed):not(.Mui-active)': {
                    color: 'var(--disabled-color)', // Default text color for not completed and not active steps
                  },
                },
              },
            },
          },
          MuiStepConnector: {
            styleOverrides: {
              lineHorizontal: {
                borderTopWidth: '3.7px',
                borderColor: 'var(--disabled-color)', // Default color for non-completed steps
              },
              lineVertical: {
                borderLeftWidth: '3.7px',
                borderColor: 'var(--disabled-color)', // Default color for non-completed steps
              },
              root: {
                '&.MuiStepConnector-alternativeLabel': {
                  paddingLeft: '8px', // Adjust spacing for alternative label
                },
                '&.Mui-completed .MuiStepConnector-line': {
                  borderColor: 'var(--main-color)', // Color for completed steps
                },
              },
            },
          },
        },
      });

      setTheme(newTheme);
      // setIsLoading(false);
    };

    createDynamicTheme(); // Initial theme creation

    return () => {
      unsubscribe();
    };
  }, [language]);

  // if (isLoading) {
  //   return <div>loadingg..</div>
  // }

  if (!theme) {
    return null; // Render nothing until the theme is created
  }

  return (
    <ThemeProvider theme={theme}>
      {language === "arabic" ? (
        <CacheProvider value={createCache({ key: 'rtl', stylisPlugins: [rtlPlugin] })}>
          {children}
        </CacheProvider>
      ) : (
        children
      )}
    </ThemeProvider>
  );
};

export default ThemeWrapper;


// import { useEffect, useState } from "react";
// import { createTheme, ThemeProvider } from "@mui/material/styles";
// import rtlPlugin from 'stylis-plugin-rtl';
// import { CacheProvider } from '@emotion/react';
// import createCache from '@emotion/cache';
// import { store } from "./index";

// const ThemeWrapper = ({ children }) => {
//   const [language, setLanguage] = useState(store.getState().language.language);
//   const [theme, setTheme] = useState(null);

//   useEffect(() => {
//     const unsubscribe = store.subscribe(() => {
//       const { language } = store.getState();
//       if (language !== store.getState().language.language) {
//         setLanguage(language);
//       }
//     });

//     const createDynamicTheme = () => {
//       const root = document.querySelector(":root");
//       const observer = new MutationObserver(() => {
//         createThemeFromCSSVariables();
//       });

//       const observerConfig = {
//         attributes: true,
//         attributeFilter: ["style"],
//         subtree: true,
//       };

//       observer.observe(root, observerConfig);
//       createThemeFromCSSVariables();

//       return () => {
//         observer.disconnect();
//       };
//     };

//     const createThemeFromCSSVariables = () => {
//       const root = document.querySelector(":root");
//       const mainColor = getComputedStyle(root).getPropertyValue("--main-color").trim();
//       const secondaryColor = getComputedStyle(root).getPropertyValue("--secondary-color");
//       const backgroundColor = getComputedStyle(root).getPropertyValue("--background-color");
//       const textColorOverBackgroundColor = getComputedStyle(root).getPropertyValue("--text-color-over-background-color")
//       const textColorOversecondaryColor = getComputedStyle(root).getPropertyValue("--text-color-over-secondary-color");
//       const lightTextColorOversecondaryColor = getComputedStyle(root).getPropertyValue("--light-text-color-over-secondary-color");
//       const mainFont = getComputedStyle(root).getPropertyValue("--main-font");
//       const errorColor = getComputedStyle(root).getPropertyValue("--input-border-error-color").trim();

//       const newTheme = createTheme({
//         direction: language?.language === "arabic" ? "rtl" : "ltr",
//         typography: {
//           fontFamily: mainFont,
//         },
//         palette: {
//           primary: {
//             main: mainColor,
//           },
//           text: {
//             secondary: textColorOverBackgroundColor,
//             primary: textColorOversecondaryColor,
//           },
//           background: {
//             default: backgroundColor,
//             paper: secondaryColor,
//           },
//           divider: "rgba(145, 158, 171, 0.24)",
//           box: "#b975e0",
//           error: {
//             main: errorColor,
//           },
//         },
//         components: {
//           MuiOutlinedInput: {
//             styleOverrides: {
//               root: {
//                 backgroundColor: `${secondaryColor}`,
//                 color: textColorOversecondaryColor,
//                 borderRadius: "12px",
//               },
//             },
//           },
//           MuiFormHelperText: {
//             styleOverrides: {
//               root: {
//                 font: "var(--font-regular)",
//                 fontSize: "var(--font-eleven)"
//               },
//             },
//           },
//           MuiSelect: {
//             styleOverrides: {
//               icon: {
//                 color: textColorOversecondaryColor,
//               },
//               '.MuiSelect-iconOpen': {
//                 color: textColorOversecondaryColor,
//               },
//             },
//           },
//           MuiInputLabel: {
//             styleOverrides: {
//               root: {
//                 fontFamily: mainFont === "Segoe UI" ? "Segoe UI Italic" : mainFont,
//                 fontSize: "var(--font-fourteen)",
//                 fontStyle: mainFont === "Segoe UI" ? "normal" : "italic",
//                 color: "var(--light-text-color-over-secondary-color)"
//               },
//             },
//           },
//         },
//       });

//       setTheme(newTheme);
//     };

//     createDynamicTheme();

//     return () => {
//       unsubscribe();
//     };
//   }, []);

//   if (!theme) {
//     return null;
//   }

//   return (
//     <ThemeProvider theme={theme}>
//       {language?.language === "arabic" ? (
//         <CacheProvider value={createCache({ key: 'rtl', stylisPlugins: [rtlPlugin] })}>
//           {children}
//         </CacheProvider>
//       ) : (
//         children
//       )}
//     </ThemeProvider>
//   );
// };

// export default ThemeWrapper;
