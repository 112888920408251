import { LOGOUT } from "./AuthActions";
import { getMenus, getAllProducts } from "../../config/config";
import { notify } from "../../utils/notify";

export const GET_MENUS_REQUEST = "GET_MENUS_REQUEST";
export const GET_MENUS_SUCCESS = "GET_MENUS_SUCCESS";
export const GET_MENUS_ERROR = "GET_MENUS_ERROR";
export const RESET_MENUS = "RESET_MENUS";
export const SET_SELECTED_MENU = "SET_SELECTED_MENU";
export const RESET_SELECTED_MENU = "CLEAR_SELECTED_MENU";
export const GET_PRODUCTS_REQUEST = "GET_PRODUCTS_REQUEST";
export const GET_PRODUCTS_SUCCESS = "GET_PRODUCTS_SUCCESS";
export const GET_PRODUCTS_ERROR = "GET_PRODUCTS_ERROR";
export const CHANGE_PRODUCTS_VIEW = "CHANGE_PRODUCTS_VIEW";
export const SET_INGREDIENTS_FILTER = "SET_INGREDIENTS_FILTER";
export const SET_TYPES_AND_ALLERGIES_FILTER = "SET_TYPES_AND_ALLERGIES_FILTER";
export const SET_TRENDING_FILTER = "SET_TRENDING_FILTER";
export const SET_OFFERS_FILTER = "SET_OFFERS_FILTER";
export const SET_FAVORITES_FILTER = "SET_FAVORITES_FILTER";
export const SET_PRICE_FILTER = "SET_PRICE_FILTER";
export const CLEAR_FILTERS = "CLEAR_FILTERS";
export const CLEAR_ALL_PRODUCTS_DATA = "CLEAR_ALL_PRODUCTS_DATA"

export const actions = {
  getMenus: (restaurantId, appSource) => async (dispatch) => {
    dispatch({ type: GET_MENUS_REQUEST });
    await getMenus(restaurantId).then((res) => {
      if (res?.status === 200) {
        dispatch({
          type: GET_MENUS_SUCCESS,
          payload: {
            menus: res?.data?.menus,
            appSource: appSource,
            selectedRestaurant: restaurantId
          },
        });
      } else {
        notify.error(res?.data?.message || "Get Menus error");
        dispatch({
          type: GET_MENUS_ERROR,
          payload: {
            appSource,
          },
        });
      }
    });
  },
  resetMenus: (appSource) => (dispatch) => {
    dispatch({
      type: RESET_MENUS,
      payload: {
        appSource: appSource,
      },
    });
  },
  setSelectedMenu: (menuId, appSource) => (dispatch) => {
    dispatch({
      type: SET_SELECTED_MENU,
      payload: {
        menuId: menuId,
        appSource: appSource,
      },
    });
  },
  resetSelectedMenu: (appSource) => (dispatch) => {
    dispatch({
      type: RESET_SELECTED_MENU,
      payload: {
        appSource: appSource,
      },
    });
  },
  getAllProducts:
    (isUserLoggedIn, restaurantId, menuId, branch, appSource, menuSelection = true) =>
      async (dispatch) => {
        dispatch({
          type: GET_PRODUCTS_REQUEST,
          payload: {
            appSource: appSource,
            menuId: menuId,
            menuSelection: menuSelection
          }
        });
        await getAllProducts(isUserLoggedIn, restaurantId, menuId).then((res) => {
          if (res?.status === 200) {
            dispatch({
              type: GET_PRODUCTS_SUCCESS,
              payload: {
                branch: branch,
                menuData: res?.data,
                comboProducts: res?.data?.menu?.comboProducts,
                products: res?.data?.menu?.products,
                groups: res?.data?.menu?.groups.sort((a, b) => a.order - b.order),
                appSource: appSource,
                menuSelection: menuSelection,
                menuId: menuId,
              },
            });
          } else {
            if (res.status === 401) {
              dispatch({ type: LOGOUT })
            }
            notify.error(res?.data?.message || "Get Products error");
            dispatch({
              type: GET_PRODUCTS_ERROR,
              payload: {
                appSource: appSource,
              },
            });
          }
        });
      },
  changeProductsView: (pView, appSource) => (dispatch) => {
    dispatch({
      type: CHANGE_PRODUCTS_VIEW,
      payload: { pView: pView, appSource: appSource },
    });
  },
  setIngredientsFilter: (filter, appSource) => (dispatch) => {
    dispatch({
      type: SET_INGREDIENTS_FILTER,
      payload: { filter: filter, appSource },
    });
  },
  // addIngredientFilterItem: (ing) => (dispatch) => {
  //   dispatch({ type: ADD_INGREDIENT_FILTER_ITEM, payload: ing });
  // },
  // removeIngredientFilterItem: (ing) => (dispatch) => {
  //   dispatch({ type: REMOVE_INGREDIENT_FILTER_ITEM, payload: ing });
  // },
  setTypesAndAllergiesFilter: (filter, appSource) => (dispatch) => {
    dispatch({
      type: SET_TYPES_AND_ALLERGIES_FILTER,
      payload: { filter: filter, appSource },
    });
  },
  // addTypesAndAllergiesFilterItem: (typeOrAllergy) => (dispatch) => {
  //   dispatch({
  //     type: ADD_TYPES_AND_ALLERGIES_FILTER_ITEM,
  //     payload: typeOrAllergy,
  //   });
  // },
  // removeTypesAndAllergiesFilterItem: (typeOrAllergy) => (dispatch) => {
  //   dispatch({
  //     type: REMOVE_TYPES_AND_ALLERGIES_FILTER_ITEM,
  //     payload: typeOrAllergy,
  //   });
  // },
  setTrendingFilter: (filter, appSource) => (dispatch) => {
    dispatch({
      type: SET_TRENDING_FILTER,
      payload: { filter: filter, appSource },
    });
  },
  setOffersFilter: (filter, appSource) => (dispatch) => {
    dispatch({
      type: SET_OFFERS_FILTER,
      payload: { filter: filter, appSource },
    });
  },
  setFavoritesFilter: (filter, appSource) => (dispatch) => {
    dispatch({
      type: SET_FAVORITES_FILTER,
      payload: { filter: filter, appSource },
    });
  },
  setPriceFilter: (filter, appSource) => (dispatch) => {
    dispatch({
      type: SET_PRICE_FILTER,
      payload: { filter: filter, appSource: appSource },
    });
  },
  clearFilters: (appSource) => (dispatch) => {
    dispatch({ type: CLEAR_FILTERS, payload: { appSource } });
  },
  clearAllProductsData: () => (dispatch) => {
    dispatch({
      type: CLEAR_ALL_PRODUCTS_DATA
    })
  }
};
