//import clear loading actions
import { CLEAR_SEND_ORDER_LOADING } from "./OrdersActions";

//import clear all data actions
import { CLEAR_ALL_ADDRESSES_DATA } from "./AddressesActions";
import { RESET_DESIGNS } from "./DesignsActions";
import { CLEAR_ALL_DISCOUNTS_DATA } from "./DiscountsActions";
import { CLEAR_ALL_EVENTS_DATA } from "./EventsActions";
import { CLEAR_ALL_FAVORITES_DATA } from "./FavoritesActions";
import { CLEAR_ALL_FEEDBACK_DATA } from "./FeedbackActions";
import { CLEAR_ALL_GALLERY_DATA } from "./GalleryActions";
import { CLEAR_ALL_LOYALTY_DATA } from "./LoyaltyActions";
import { CLEAR_ALL_MEDIA_DATA } from "./MediaActions";
import { CLEAR_ALL_MESSAGES_DATA } from "./MessagesActions";
import { CLEAR_ALL_ORDERS_DATA } from "./OrdersActions";
import { CLEAR_ALL_POPULAR_ITEMS_DATA } from "./PopularItemsActions";
import { CLEAR_ALL_PRODUCTS_DATA } from "./ProductsActions";
import { CLEAR_ALL_PROMOTIONS_DATA } from "./PromotionsActions";
import { CLEAR_ALL_RESTAURANTS_DATA } from "./RestaurantsActions";

export const actions = {
    clearLoading: () => async (dispatch) => {
        dispatch({
            type: CLEAR_SEND_ORDER_LOADING
        })
    },

    clearAllData: () => (dispatch) => {
        dispatch({
            type: CLEAR_ALL_ADDRESSES_DATA
        })
        // dispatch({
        //     type: RESET_DESIGNS
        // })
        dispatch({
            type: CLEAR_ALL_DISCOUNTS_DATA
        })
        dispatch({
            type: CLEAR_ALL_EVENTS_DATA
        })
        dispatch({
            type: CLEAR_ALL_FAVORITES_DATA
        })
        dispatch({
            type: CLEAR_ALL_FEEDBACK_DATA
        })
        dispatch({
            type: CLEAR_ALL_GALLERY_DATA
        })
        dispatch({
            type: CLEAR_ALL_LOYALTY_DATA
        })
        dispatch({
            type: CLEAR_ALL_MEDIA_DATA
        })
        dispatch({
            type: CLEAR_ALL_MESSAGES_DATA
        })
        dispatch({
            type: CLEAR_ALL_ORDERS_DATA
        })
        dispatch({
            type: CLEAR_ALL_POPULAR_ITEMS_DATA
        })
        dispatch({
            type: CLEAR_ALL_PRODUCTS_DATA
        })
        dispatch({
            type: CLEAR_ALL_PROMOTIONS_DATA
        })
        dispatch({
            type: CLEAR_ALL_RESTAURANTS_DATA
        })
    }
}
