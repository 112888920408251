import { getMedia, addMediaInteraction } from "../../config/config";
import { LOGOUT } from "./AuthActions";
import { notify } from "../../utils/notify";

export const SET_MEDIA_RESTAURANT = "SET_MEDIA_RESTAURANT";
export const GET_MEDIA_REQUEST = "GET_MEDIA_REQUEST";
export const GET_MEDIA_SUCCESS = "GET_MEDIA_SUCCESS";
export const GET_MEDIA_ERROR = "GET_MEDIA_ERROR";
export const ADD_MEDIA_INTERACTION_REQUEST = "ADD_MEDIA_INTERACTION_REQUEST";
export const ADD_MEDIA_INTERACTION_SUCCESS = "ADD_MEDIA_INTERACTION_SUCCESS";
export const ADD_MEDIA_INTERACTION_ERROR = "ADD_MEDIA_INTERACTION_ERROR";
export const CLEAR_ALL_MEDIA_DATA = "CLEAR_ALL_MEDIA_DATA";

export const actions = {
  setMediaRestaurant: (appSource, uuid) => (dispatch) => {
    dispatch({
      type: CLEAR_ALL_MEDIA_DATA,
      payload: {
        appSource: appSource,
        uuid: uuid
      }
    });
  },
  getMedia:
    (
      isUserLoggedIn,
      restaurantId,
      appSource,
      page,
      limit,
      onSuccess
    ) =>
      async (dispatch) => {
        dispatch({ type: GET_MEDIA_REQUEST });
        try {
          const res = await getMedia(isUserLoggedIn, restaurantId, page, limit);
          if (res.status === 200) {
            if (onSuccess) {
              onSuccess();
            }
            dispatch({
              type: GET_MEDIA_SUCCESS,
              payload: {
                media: res?.data?.media,
                totalRecords: res?.data?.totalRecords,
                totalPages: res?.data?.totalPages,
                currentPage: res?.data?.currentPage,
                appSource: appSource,
              },
            });
          } else {
            if (res.status === 401) {
              dispatch({ type: LOGOUT });
            }
            dispatch({
              type: GET_MEDIA_ERROR,
            });
            notify.error(res?.data?.message || "Get Media error"); 
          }
        } catch (error) {
          dispatch({
            type: GET_MEDIA_ERROR,
          });
          notify.error(error?.res?.data?.message || "Get Media error"); 
        }
      },
  addMediaInteraction:
    (restaurantId, mediaId, data, appSource) => async (dispatch) => {
      dispatch({
        type: ADD_MEDIA_INTERACTION_REQUEST,
        payload: {
          mediaId: mediaId,
          data: data,
          appSource: appSource,
        },
      });
      try {
        const res = await addMediaInteraction(restaurantId, mediaId, data);
        if (res.status === 200) {
          dispatch({
            type: ADD_MEDIA_INTERACTION_SUCCESS,
            payload: {
              mediaId: mediaId,
              data: data,
              appSource: appSource,
            },
          });
        } else {
          if (res.status === 401) {
            dispatch({ type: LOGOUT });
          }
          dispatch({
            type: ADD_MEDIA_INTERACTION_ERROR,
            payload: {
              data: data,
              appSource: appSource,
            },
          });
          notify.error(res?.data?.message || "Add Interaction error"); 
        }
      } catch (error) {
        notify.error(error?.res?.data?.message || "Add Interaction error"); 
        dispatch({
          type: ADD_MEDIA_INTERACTION_ERROR,
          payload: {
            appSource: appSource,
          },
        });
      }
    },
  clearAllMediaData: () => (dispatch) => {
    dispatch({ type: CLEAR_ALL_MEDIA_DATA });
  },
};
