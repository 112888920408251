import { getDesigns } from "../../config/config";
import { notify } from "../../utils/notify";

export const SET_LAST_APP_OPEN_TIME = "SET_LAST_APP_OPEN_TIME";
export const GET_DESIGNS_REQUEST = "GET_DESIGNS_REQUEST";
export const GET_DESIGNS_SUCCESS = "GET_DESIGNS_SUCCESS";
export const GET_DESIGNS_ERROR = "GET_DESIGNS_ERROR";
export const SET_SELECTED_THEME = "SET_SELECTED_THEME";
export const UPDATE_CURRENT_RESTAURANT_THEME_DATA = "UPDATE_CURRENT_RESTAURANT_THEME_DATA";
export const RESET_DESIGNS = "RESET_DESIGNS";

export const actions = {
    setLastAppOpenTime: () => (dispatch) => {
        dispatch({
            type: SET_LAST_APP_OPEN_TIME,
            payload: {
                value: Date.now()
            }
        });
    },
    getDesigns: () => async (dispatch) => {
        dispatch({ type: GET_DESIGNS_REQUEST });
        await getDesigns().then((res) => {
            if (res?.status === 200) {
                dispatch({
                    type: GET_DESIGNS_SUCCESS,
                    payload: {
                        designs: res?.data?.restaurantDesigns,
                        loginDesignSetup: res?.data?.loginDesignSetup,
                        splashScreen: res?.data?.splashScreen
                    },
                });
            } else {
                notify.error(res?.data?.message || "Get Design Settings error");
                dispatch({
                    type: GET_DESIGNS_ERROR,
                });
            }
        });
    },
    setSelectedTheme: (value) => (dispatch) => {
        dispatch({
            type: SET_SELECTED_THEME,
            payload: {
                value: value
            }
        });
    },
    updateCurrentRestaurantThemeData: (ruuid, mode) => (dispatch) => {
        dispatch({
            type: UPDATE_CURRENT_RESTAURANT_THEME_DATA,
            payload: {
                ruuid: ruuid,
                mode: mode
            }
        });
    },
    resetDesigns: () => (dispatch) => {
        dispatch({ type: RESET_DESIGNS });
    }
};
