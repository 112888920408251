export const SET_LANGUAGE = 'SET_LANGUAGE';

export const actions = {
  setLanguage: (value) => (dispatch) => {
    dispatch({
        type: SET_LANGUAGE,
        payload: value
    });
  }
};
