import moment from "moment";
import {
  GET_RESERVATIONS_REQUEST,
  GET_RESERVATIONS_SUCCESS,
  GET_RESERVATIONS_ERROR,
  NEW_RESERVATION_START,
  INCREMENT_ACTIVE_STEP,
  DECREMENT_ACTIVE_STEP,
  NEW_RESERVATION_PROGRESS,
  INCREMENT_NEW_RESERVATION_DATE,
  DECREMENT_NEW_RESERVATION_DATE,
  INCREMENT_DECREMENT_NEW_RESERVATION_GUESTS,
  INCREMENT_NEW_RESERVATION_HOUR,
  DECREMENT_NEW_RESERVATION_HOUR,
  INCREMENT_NEW_RESERVATION_MINUTES,
  DECREMENT_NEW_RESERVATION_MINUTES,
  NEW_RESERVATION_AM_PM_SWITCHING,
  UPDATE_NEW_RESERVATION_DATA,
  UPDATE_NEW_RESERVATION_USER_INFO,
  SUBMIT_NEW_RESERVATION,
  NEW_RESERVATION_END,
  DISCARD_NEW_RESERVATION,
} from "../actions/ReservationsActions";

const initialState = {
  newReservationInProgress: false,
  newReservationActiveStep: -1,
  newReservationData: {
    branch: "",
    area: 2,
    date: "",
    time: "",
    adults: -1,
    children: -1,
    userInfo: {
      firstName: "",
      lastName: "",
      email: "",
      phone: {
        phone: "",
        country: "",
        dialCode: "",
      },
      birthYear: -1,
      birthMonth: -1,
      birthDay: -1,
      cardNumber: "",
      cardHolderName: "",
      cardExpirationMonth: "",
      cardExpirationYear: "",
      cardCVV: "",
      restrictionsAndAllergies: [],
      specialOccasion: "",
      cancellationPolicyAgreement: true,
      termsAndConditionsAgreement: true,
      receiveNewsAndOffers: false,
    },
  },
  reservations: [],
};

function reservation(state = initialState, action) {
  switch (action.type) {
    case GET_RESERVATIONS_REQUEST:
      return Object.assign({}, state, { loading: true });
    case GET_RESERVATIONS_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        reservations: action.payload.reservations,
      });
    case GET_RESERVATIONS_ERROR:
      return Object.assign({}, state, {
        loading: false,
        reservations: [],
      });
    case NEW_RESERVATION_START:
      return Object.assign({}, state, {
        newReservationInProgress: false,
        newReservationActiveStep: 0,
        // newReservationData: {
        //   branch: state.newReservationData?.branch || 1,
        // },
      });
    case INCREMENT_ACTIVE_STEP:
      return Object.assign({}, state, {
        newReservationActiveStep: state.newReservationActiveStep + 1,
      });
    case DECREMENT_ACTIVE_STEP:
      return Object.assign({}, state, {
        newReservationActiveStep: state.newReservationActiveStep - 1,
      });
    case UPDATE_NEW_RESERVATION_DATA:
      return Object.assign({}, state, {
        newReservationData: {
          ...state.newReservationData,
          [action?.payload?.field]: action?.payload?.value,
        },
      });
    case INCREMENT_DECREMENT_NEW_RESERVATION_GUESTS:
      if (
        action.payload.field !== "children" &&
        action.payload.field !== "adults"
      ) {
        return state;
      }

      const currentValue = state.newReservationData[action.payload.field];
      const newValue = currentValue + action.payload.value;

      if (currentValue === 0 && action.payload.value < 0) {
        return state;
      }

      return Object.assign({}, state, {
        newReservationData: Object.assign({}, state.newReservationData, {
          [action.payload.field]: newValue,
        }),
      });
    case INCREMENT_NEW_RESERVATION_DATE:
      return Object.assign({}, state, {
        newReservationData: {
          ...state.newReservationData,
          date: moment(state.newReservationData.date).add(1, "days"),
        },
      });
    case DECREMENT_NEW_RESERVATION_DATE:
      return Object.assign({}, state, {
        newReservationData: {
          ...state.newReservationData,
          date: moment(state.newReservationData.date).subtract(1, "days"),
        },
      });

    case INCREMENT_NEW_RESERVATION_HOUR:
      return Object.assign({}, state, {
        newReservationData: {
          ...state.newReservationData,
          time: moment(
            `${
              parseInt(moment(state.newReservationData.time).format("h")) + 1
            }:${moment(state.newReservationData.time).format("mm")}${moment(
              state.newReservationData.time
            ).format("a")}`,
            "h:mma"
          ),
        },
      });
    case DECREMENT_NEW_RESERVATION_HOUR:
      return Object.assign({}, state, {
        newReservationData: {
          ...state.newReservationData,
          time: moment(
            `${
              parseInt(moment(state.newReservationData.time).format("h")) - 1
            }:${moment(state.newReservationData.time).format("mm")}${moment(
              state.newReservationData.time
            ).format("a")}`,
            "h:mma"
          ),
        },
      });
    case INCREMENT_NEW_RESERVATION_MINUTES:
      return Object.assign({}, state, {
        newReservationData: {
          ...state.newReservationData,
          time: moment(moment(state.newReservationData.time)).add(
            15,
            "minutes"
          ),
        },
      });
    case DECREMENT_NEW_RESERVATION_MINUTES:
      return Object.assign({}, state, {
        newReservationData: {
          ...state.newReservationData,
          time: moment(
            moment(state.newReservationData.time).subtract(15, "minutes")
          ),
        },
      });
    case NEW_RESERVATION_AM_PM_SWITCHING:
      return Object.assign({}, state, {
        newReservationData: {
          ...state.newReservationData,
          time: moment(
            `${moment(state.newReservationData.time).format("h")}:${moment(
              state.newReservationData.time
            ).format("mm")}${action.payload.toLowerCase()}}`,
            "h:mma"
          ),
        },
      });
    case UPDATE_NEW_RESERVATION_USER_INFO:
      const field = action.payload.field;
      const value = action.payload.value;
      const restrictionsAndAllergies =
        state?.newReservationData?.userInfo?.restrictionsAndAllergies?.slice(); // create a copy of the restrictionsAndAllergies array

      if (
        field === "cancellationPolicyAgreement" ||
        field === "termsAndConditionsAgreement" ||
        field === "receiveNewsAndOffers"
      ) {
        // toggle field
        return Object.assign({}, state, {
          newReservationData: {
            ...state.newReservationData,
            userInfo: {
              ...state.newReservationData.userInfo,
              [field]: !state.newReservationData.userInfo[field],
            },
          },
        });
      } else if (field === "restrictionsAndAllergies") {
        // add or remove restrictionsAndAllergies
        if (value === "clear") {
          restrictionsAndAllergies?.splice(0, restrictionsAndAllergies?.length); // remove all restrictionsAndAllergies
        } else {
          const allergyIndex = restrictionsAndAllergies?.indexOf(value);
          if (allergyIndex === -1) {
            restrictionsAndAllergies?.push(value);
          } else {
            restrictionsAndAllergies?.splice(allergyIndex, 1);
          }
        }
        return Object.assign({}, state, {
          newReservationData: {
            ...state.newReservationData,
            userInfo: {
              ...state.newReservationData.userInfo,
              restrictionsAndAllergies: restrictionsAndAllergies,
            },
          },
        });
      } else {
        // update fields
        return Object.assign({}, state, {
          newReservationData: {
            ...state.newReservationData,
            userInfo: {
              ...state.newReservationData.userInfo,
              [field]: value,
            },
          },
        });
      }
    case NEW_RESERVATION_PROGRESS:
      return Object.assign({}, state, {
        newReservationInProgress: true,
        newReservationActiveStep: 1,
      });
    case SUBMIT_NEW_RESERVATION:
      return Object.assign({}, state, {
        reservations: [
          ...state.reservations,
          { ...state.newReservationData, status: "confirmed" },
        ],
        newReservationInProgress: false,
      });
    case NEW_RESERVATION_END:
      return Object.assign({}, state, {
        reservations: state.reservations,
        newReservationData: initialState.newReservationData,
        newReservationActiveStep: initialState.newReservationActiveStep,
        newReservationInProgress: initialState.newReservationInProgress,
      });
    case DISCARD_NEW_RESERVATION:
      return Object.assign({}, state, {
        reservations: state.reservations,
        newReservationData: initialState.newReservationData,
        newReservationActiveStep: initialState.newReservationActiveStep,
        newReservationInProgress: initialState.newReservationInProgress,
      });
    default:
      return state;
  }
}

export default reservation;
