export const GET_RESERVATIONS_REQUEST = 'GET_RESERVATIONS_REQUEST';
export const GET_RESERVATIONS_SUCCESS = 'GET_RESERVATIONS_SUCCESS';
export const GET_RESERVATIONS_ERROR = 'GET_RESERVATIONS_ERROR';
export const NEW_RESERVATION_START = 'ADD_RESERVATION_START';
export const INCREMENT_ACTIVE_STEP = 'INCREMENT_ACTIVE_STEP';
export const DECREMENT_ACTIVE_STEP = 'DECREMENT_ACTIVE_STEP';
export const NEW_RESERVATION_PROGRESS = 'NEW_RESERVATION_PROGRESS';
export const UPDATE_NEW_RESERVATION_DATA = 'UPDATE_NEW_RESERVATION_DATA';
export const INCREMENT_DECREMENT_NEW_RESERVATION_GUESTS = 'INCREMENT_DECREMENT_NEW_RESERVATION_GUESTS';
export const INCREMENT_NEW_RESERVATION_DATE = 'INCREMENT_NEW_RESERVATION_DATE';
export const DECREMENT_NEW_RESERVATION_DATE = 'DECREMENT_NEW_RESERVATION_DATE';
export const INCREMENT_NEW_RESERVATION_ADULTS =
  'INCREMEN_NEW_RESERVATION_ADULTS';
export const DECREMENT_NEW_RESERVATION_ADULTS =
  'DECREMENT_NEW_RESERVATION_ADULTS';
export const INCREMENT_NEW_RESERVATION_CHILDREN =
  'INCREMENT_NEW_RESERVATION_CHILDREN';
export const DECREMENT_NEW_RESERVATION_CHILDREN =
  'DECREMENT_NEW_RESERVATION_CHILDREN';
export const INCREMENT_NEW_RESERVATION_HOUR = 'INCREMENT_NEW_RESERVATION_HOUR';
export const INCREMENT_NEW_RESERVATION_MINUTES =
  'INCREMENT_NEW_RESERVATION_MINUTES';
export const DECREMENT_NEW_RESERVATION_HOUR = 'DECREMENT_NEW_RESERVATION_HOUR';
export const DECREMENT_NEW_RESERVATION_MINUTES =
  'DECREMENT_NEW_RESERVATION_MINUTES';
export const NEW_RESERVATION_AM_PM_SWITCHING =
  'NEW_RESERVATION_AM_PM_SWITCHING';
export const UPDATE_NEW_RESERVATION_USER_INFO = 'UPDATE_NEW_RESERVATION_USER_INFO';
export const SUBMIT_NEW_RESERVATION = 'SUBMIT_NEW_RESERVATION';
export const NEW_RESERVATION_END = 'ADD_RESERVATION_END';
export const DISCARD_NEW_RESERVATION = 'DISCARD_NEW_RESERVATION';

const actions = {
  newReservationStart: () => (dispatch) => {
    dispatch({ type: NEW_RESERVATION_START });
  },
  newReservationProgress: () => (dispatch) => {
    dispatch({ type: NEW_RESERVATION_PROGRESS });
  },
  incrementActiveStep: () => (dispatch) => {
    dispatch({ type: INCREMENT_ACTIVE_STEP });
  },
  decrementActiveStep: () => (dispatch) => {
    dispatch({ type: DECREMENT_ACTIVE_STEP });
  },
  incrementNewReservationDate: () => (dispatch) => {
    dispatch({ type: INCREMENT_NEW_RESERVATION_DATE });
  },
  decrementNewReservationDate: () => (dispatch) => {
    dispatch({ type: DECREMENT_NEW_RESERVATION_DATE });
  },
  incrementNewReservationHour: () => (dispatch) => {
    dispatch({ type: INCREMENT_NEW_RESERVATION_HOUR });
  },
  decrementNewReservationHour: () => (dispatch) => {
    dispatch({ type: DECREMENT_NEW_RESERVATION_HOUR });
  },
  incrementNewReservationMinutes: () => (dispatch) => {
    dispatch({ type: INCREMENT_NEW_RESERVATION_MINUTES });
  },
  decrementNewReservationMinutes: () => (dispatch) => {
    dispatch({ type: DECREMENT_NEW_RESERVATION_MINUTES });
  },
  newReservationAmPmSwitching: (period) => (dispatch) => {
    dispatch({ type: NEW_RESERVATION_AM_PM_SWITCHING, payload: period });
  },
  incrementDecrementNewReservationGuests: (field,value) => (dispatch) => {
    dispatch({ type: INCREMENT_DECREMENT_NEW_RESERVATION_GUESTS, payload:{field,value}});
  },
  updateNewReservationData: (field, value) => (dispatch) => {
    dispatch({type: UPDATE_NEW_RESERVATION_DATA, payload: {field, value}})
  },
  updateNewReservationUserInfo : (field, value) => (dispatch) => {
    dispatch({type: UPDATE_NEW_RESERVATION_USER_INFO, payload: {field, value}})
  },
  submitNewReservation: () => (dispatch) => {
    dispatch({ type: SUBMIT_NEW_RESERVATION });
  },
  newReservationEnd: () => (dispatch) => {
    dispatch({ type: NEW_RESERVATION_END });
  },
  discardNewReservation: () => (dispatch) => {
    dispatch({
      type: DISCARD_NEW_RESERVATION,
    });
  },
};

export default actions;
