import {
  GET_POPULAR_ITEMS_REQUEST,
  GET_POPULAR_ITEMS_SUCCESS,
  GET_POPULAR_ITEMS_ERROR,
  CLEAR_ALL_POPULAR_ITEMS_DATA
} from "../actions/PopularItemsActions";

const initialState = {
  loading: false,
  popularItems: [],
  dineInPopularItems: [],
  payPopularItems: [],
  QRPopularItems: [],
};

function popularItems(state = initialState, action) {
  const appSource = action?.payload?.appSource;
  const popularItemsState = appSource === "dine-in"
    ? "dineInPopularItems"
    : appSource === "pay"
      ? "payPopularItems"
      : appSource === "QR"
        ? "QRPopularItems"
        : "popularItems"

  switch (action.type) {
    case GET_POPULAR_ITEMS_REQUEST:
      return Object.assign({}, state, {
        loading: state[popularItemsState]?.length > 0 ? false : true,
      });
    case GET_POPULAR_ITEMS_SUCCESS:
      setTimeout(() => { });
      return Object.assign({}, state, {
        loading: false,
        [popularItemsState]: action.payload.popularItems,
      });

    case GET_POPULAR_ITEMS_ERROR:
      return Object.assign({}, state, {
        loading: false,
      });
    case CLEAR_ALL_POPULAR_ITEMS_DATA:
      return initialState;
    default:
      return state;
  }
}

export default popularItems;
