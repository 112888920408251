import {
  GET_DISCOUNTS_REQUEST,
  GET_DISCOUNTS_SUCCESS,
  GET_DISCOUNTS_ERROR,
  GET_DISCOUNT_REQUEST,
  GET_DISCOUNT_SUCCESS,
  GET_DISCOUNT_ERROR,
  CLEAR_ALL_DISCOUNTS_DATA,
} from "../actions/DiscountsActions";

const initialState = {
  discounts: null,
  getDiscountsLoading: false,
  discount: null,
  getDiscountLoading: false,
};

function discounts(state = initialState, action) {
  switch (action.type) {
    case GET_DISCOUNTS_REQUEST:
      return Object.assign({}, state, {
        getDiscountsLoading: state?.discounts ? false : true,
      });
    case GET_DISCOUNTS_SUCCESS:
      return Object.assign({}, state, {
        getDiscountsLoading: false,
        discounts: action.payload.discounts,
      });
    case GET_DISCOUNTS_ERROR:
      return Object.assign({}, state, {
        getDiscountsLoading: false,
      });
    case GET_DISCOUNT_REQUEST:
      return Object.assign({}, state, {
        getDiscountLoading: true,
      });
    case GET_DISCOUNT_SUCCESS:
      return Object.assign({}, state, {
        getDiscountLoading: false,
        discount: action.payload.discount,
      });
    case GET_DISCOUNT_ERROR:
      return Object.assign({}, state, {
        getDiscountLoading: false,
        discount: null,
      });
    case CLEAR_ALL_DISCOUNTS_DATA:
      return initialState;
    default:
      return state;
  }
}

export default discounts;

