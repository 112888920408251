import { notify } from "../../utils/notify";
import { getFavorites, addFavorite, deleteFavorite } from "../../config/config";
import { LOGOUT } from './AuthActions';

export const GET_FAVORITES_REQUEST = "GET_FAVORITES_REQUEST";
export const GET_FAVORITES_SUCCESS = "GET_FAVORITES_SUCCESS";
export const GET_FAVORITES_ERROR = "GET_FAVORITES_ERROR";
export const ADD_FAVORITE_REQUEST = "ADD_FAVORITE_REQUEST";
export const ADD_FAVORITE_SUCCESS = "ADD_FAVORITE_SUCCESS";
export const ADD_FAVORITE_ERROR = "ADD_FAVORITE_ERROR";
export const DELETE_FAVORITE_REQUEST = "DELETE_FAVORITE_REQUEST";
export const DELETE_FAVORITE_SUCCESS = "DELETE_FAVORITE_SUCCESS";
export const DELETE_FAVORITE_ERROR = "DELETE_FAVORITE_ERROR";
export const CLEAR_ALL_FAVORITES_DATA = "CLEAR_FAVORITES";

export const actions = {
  //GET FAVORITES
  getFavorites: (restaurantId, appSource) => async (dispatch) => {
    dispatch({
      type: GET_FAVORITES_REQUEST,
      payload: {
        restaurantId: restaurantId,
        appSource: appSource
      }
    });
    await getFavorites(restaurantId).then((res) => {
      if (res?.status === 200) {
        dispatch({
          type: GET_FAVORITES_SUCCESS,
          payload: {
            favorites: res?.data?.favorites,
            restaurantId: restaurantId,
            appSource: appSource
          },
        });
      } else {
        if (res?.status === 401) {
          dispatch({ type: LOGOUT })
        }
        notify.error(res?.data?.message || "Get Favorites error");
        dispatch({
          type: GET_FAVORITES_ERROR,
          restaurantId: restaurantId,
          appSource: appSource,
        });
      }
    });
  },

  //ADD FAVORITE
  addFavorite: (restaurantId, data, appSource) => async (dispatch) => {
    dispatch({
      type: ADD_FAVORITE_REQUEST,
      payload: {
        restaurantId: restaurantId,
        data: data,
        appSource: appSource
      }
    });
    await addFavorite(restaurantId, data).then((res) => {
      if (res?.status === 200) {
        dispatch({
          type: ADD_FAVORITE_SUCCESS,
          payload: {
            restaurantId: restaurantId,
            appSource: appSource
          }
        })
      } else {
        if (res?.status === 401) {
          dispatch({ type: LOGOUT })
        }
        notify.error(res?.data?.message || "Add favorite error");
        dispatch({
          type: ADD_FAVORITE_ERROR,
          restaurantId: restaurantId,
          data: data,
          appSource: appSource,
        });
      }
    })
  },

  //DELETE FAVORITE
  deleteFavorite: (restaurantId, favorite, appSource) => async (dispatch) => {
    dispatch({
      type: DELETE_FAVORITE_REQUEST,
      payload: {
        restaurantId: restaurantId,
        appSource: appSource,
        data: favorite
      }
    });
    await deleteFavorite(restaurantId, favorite?.product?.uuid).then((res) => {
      if (res?.status === 200) {
        dispatch({
          type: DELETE_FAVORITE_SUCCESS,
          payload: {
            restaurantId: restaurantId,
            appSource: appSource
          }
        })
      } else {
        if (res?.status === 401) {
          dispatch({ type: LOGOUT })
        }
        notify.error(res?.data?.message || "Delete favorite error");
        dispatch({
          type: DELETE_FAVORITE_ERROR,
          restaurantId: restaurantId,
          appSource: appSource,
          data: favorite
        });
      }
    })
  },
  //CLEAR FAVORITES
  clearAllFavoritesData: () => (dispatch) => {
    dispatch({
      type: CLEAR_ALL_FAVORITES_DATA
    })
  }
  // clearFavorites: (appSource) => async (dispatch) => {
  //   dispatch({
  //     type: CLEAR_FAVORITES,
  //     payload: {
  //       appSource: appSource,
  //     }
  //   });
  // }
}
// export const actions = {
//   //GET LOYALTY INFO
//   getLoyaltyInfo: (restaurantId, appSource) => async (dispatch) => {
//     dispatch({
//       type: GET_LOYALTY_INFO_REQUEST
//     });
//     await getLoyalty(restaurantId).then((res) => {
//       if (res?.status === 200) {
//         dispatch({
//           type: GET_LOYALTY_INFO_SUCCESS,
//           payload: {
//             loyalty: res?.data?.loyality,
//             appSource: appSource
//           },
//         });
//       } else {
//         notifiy.error(res?.data?.message || "Get Loyalty error");
//         dispatch({
//           type: GET_LOYALTY_INFO_ERROR,
//           appSource: appSource
//         });
//       }
//     });
//   },
// }

// export const ADD_TO_FAVORITES_SUCCESS = 'ADD_TO_FAVORITES_SUCCESS';
// export const REMOVE_FROM_FAVORITES_SUCCESS = 'REMOVE_FROM_FAVORITES_SUCCESS';

// export const actions = {
//   //ADD TO FAVORITES
//   addToFavorites: (restoUuid, newProduct) => (dispatch) => {
//     dispatch({
//       type: ADD_TO_FAVORITES_SUCCESS,
//       payload: {
//         restoUuid: restoUuid,
//         newProduct: newProduct
//       },
//     });
//   },

//   //REMOVE FROM FAVORITES
//   removeFromFavorites: (restoUuid, product) => (dispatch) => {
//     dispatch({
//       type: REMOVE_FROM_FAVORITES_SUCCESS,
//       payload: {
//         restoUuid: restoUuid,
//         product: product
//       },
//     });
//   },
// };
