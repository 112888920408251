import { toast } from "react-toastify"
import { registerDevice } from "../../config/config"

export const SET_FCM_TOKEN = 'SET_FCM_TOKEN'

export const REGISTRATION_REQUEST = 'REGISTRATION_REQUEST'
export const REGISTRATION_SUCCESS = 'REGISTRATION_SUCCESS'
export const REGISTRATION_ERROR = 'REGISTRATION_ERROR'

export const actions = {
    setFcmToken: (token) => (dispatch) => {
        dispatch({
            type: SET_FCM_TOKEN,
            payload: token
        })
    },
    registerDevice: (token) => async (dispatch) => {
        dispatch({
            type: REGISTRATION_REQUEST
        })
        await registerDevice(token).then((response) => {
            if (response.status === 200) {
                dispatch({
                    type: REGISTRATION_SUCCESS,
                })
            } else {
                dispatch({
                    type: REGISTRATION_ERROR
                })
            }
        })
    }
}
