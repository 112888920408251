import { LOGOUT } from "./AuthActions";
import { getPromotions, getPromotion } from "../../config/config";
import { notify } from "../../utils/notify";

export const GET_PROMOTIONS_REQUEST = "GET_PROMOTIONS_REQUEST";
export const GET_PROMOTIONS_SUCCESS = "GET_PROMOTIONS_SUCCESS";
export const GET_PROMOTIONS_ERROR = "GET_PROMOTIONS_ERROR";
export const GET_PROMOTION_REQUEST = "GET_PROMOTION_REQUEST";
export const GET_PROMOTION_SUCCESS = "GET_PROMOTION_SUCCESS";
export const GET_PROMOTION_ERROR = "GET_PROMOTION_ERROR";
export const CLEAR_ALL_PROMOTIONS_DATA = "CLEAR_ALL_PROMOTIONS_DATA";

export const actions = {
  getPromotions: (isUserLoggedIn, restaurantId) => async (dispatch) => {
    dispatch({ type: GET_PROMOTIONS_REQUEST });
    await getPromotions(isUserLoggedIn, restaurantId).then((res) => {
      if (res?.status === 200 || res?.status === 501) {
        dispatch({
          type: GET_PROMOTIONS_SUCCESS,
          payload: {
            promotions: res?.data?.userPromotions,
          },
        });
      } else {
        if (res?.status === 401) {
          dispatch({ type: LOGOUT })
        }
        notify.error(res?.data?.message || "Get Promotions error");
        dispatch({
          type: GET_PROMOTIONS_ERROR,
        });
      }
    });
  },
  getPromotion:
    (isUserLoggedIn, restaurantId, promotionId) => async (dispatch) => {
      dispatch({ type: GET_PROMOTION_REQUEST });
      await getPromotion(isUserLoggedIn, restaurantId, promotionId).then(
        (res) => {
          if (res?.status === 200) {
            dispatch({
              type: GET_PROMOTION_SUCCESS,
              payload: {
                promotion: res?.data?.promotion,
              },
            });
          } else {
            if (res?.status === 401) {
              dispatch({ type: LOGOUT })
            }
            notify.error(res?.data?.message || "Get Promotion error");
            dispatch({
              type: GET_PROMOTION_ERROR,
            });
          }
        }
      );
    },
  clearAllPromotionsData: () => (dispatch) => {
    dispatch({ type: CLEAR_ALL_PROMOTIONS_DATA });
  },
};
