import {
  GET_GALLERY_REQUEST,
  GET_GALLERY_SUCCESS,
  GET_GALLERY_ERROR,
  RESET_GALLERY,
  CLEAR_ALL_GALLERY_DATA
} from "../actions/GalleryActions";

const initialState = {
  loading: false,
  layout: null,
  gallery: [],
  //DINE IN
  dineInLayout: null,
  dineInGallery: [],
  //PAY
  payLayout: null,
  payGallery: [],
  //QR
  QRLayout: null,
  QRGallery: [],
};

function gallery(state = initialState, action) {
  const appSource = action?.payload?.appSource;
  const galleryState =
    appSource === "dine-in"
      ? "dineInGallery"
      : appSource === "pay"
        ? "payGallery"
        : appSource === "QR"
          ? "QRGallery"
          : "gallery";
  const layoutState =
    appSource === "dine-in"
      ? "dineInLayout"
      : appSource === "pay"
        ? "payLayout"
        : appSource === "QR"
          ? "QRLayout"
          : "layout";
  switch (action.type) {
    case GET_GALLERY_REQUEST:
      return Object.assign({}, state, {
        loading: true,
      });
    case GET_GALLERY_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        [layoutState]: action.payload.layout,
        [galleryState]: action.payload.gallery,
      });
    case GET_GALLERY_ERROR:
      return Object.assign({}, state, {
        loading: false,
      });
    case RESET_GALLERY:
      return Object.assign({}, state, {
        [layoutState]: null,
        [galleryState]: [],
      });
    case CLEAR_ALL_GALLERY_DATA:
      return initialState;
    default:
      return state;
  }
}

export default gallery;
